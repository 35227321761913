const ApiUrlServices = {
  CREATE_GET_MODULE: '/modules',
  CREATE_VIDEO: '/videos',
  UPDATE_VIDEO: (videoId) => `/videos/${videoId}`,
  GET_ALL_ADMIN_Videos: '/admin/home',
  SHARE_VIDEO: '/share_videos',
  GET_ALL_CLIENT_VIDEOS: (shared_id) => `/share_videos/${shared_id}`,
  CREATE_VIDEOS_FEEDBACK: (shared_id) => `/shared_videos/${shared_id}/rating`,
  DELETE_ADMIN_Videos: (id) => `/videos/${id}`,
  GET_ALL_VIDEO_BY_MODULE: (module_id) => `/modules/${module_id}/videos`,
  GET_PATIENT: (shared_id) => `/opt_in/${shared_id}`,
  OTP_IN: '/opt_in',
};

export default ApiUrlServices;
