import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from "./routes";
import { ToastContainer } from 'react-toastify';
import './lang/i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// ** Import custom components for redux **
// import { Provider, useDispatch } from 'react-redux';
// import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
// import store from '../src/store';

// Configure Amplify for file upload to S3
import { S3_Pool_conf } from "./config/aws-amplify";
import { Amplify } from 'aws-amplify'
import axios from "axios";
import { api_base_url } from "./config/config";
import ApiUrlServices from "./networks/ApiUrlServices";
import { userData } from "./config/sessionKeys";
import { userAction } from "./store/userSlice";
import path from "./routes/path";
import queryString from 'query-string';
import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

Amplify.configure(S3_Pool_conf);

// use store for Redux
let persist = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persist}>
      <BrowserRouter basename={`/`}>
        <ToastContainer />
        <MainRoutes />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// **************** Author: Kazi Nayem || kazi.nayem@shadhinlab.com *************** //
// **************** React 17 *************** //
// ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
