import React from 'react';
import Skeleton from "react-loading-skeleton";

function SelectonVideoSection(props) {
    let quantity = props.quantity ? props.quantity : 1

    return (
        <>
            {[...Array(quantity)].map((x, i) =>
                <div
                    style={{
                        borderBottom: i !== quantity - 1 ? '1px solid #0000001A;' : 'none',
                    }}
                    key={i} className={`video-section ${props.col}`}>
                    <Skeleton
                        height="24px"
                        ccount={1}
                        width="120px"
                        className="mb-10"
                    />

                    <div className="row">
                        <div className="col-lg-3 col-md-4 mb-20 col-sm-6">
                            <Skeleton
                                height="150px"
                                count={1}
                                className="mb-10"
                            />
                            <Skeleton
                                count={1}
                                width="60%"
                            />
                            <Skeleton
                                count={1}
                            />
                            <Skeleton
                                count={1}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 mb-20 col-sm-6">
                            <Skeleton
                                height="150px"
                                count={1}
                                className="mb-10"
                            />
                            <Skeleton
                                count={1}
                                width="60%"
                            />
                            <Skeleton
                                count={1}
                            />
                            <Skeleton
                                count={1}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 mb-20 col-sm-6">
                            <Skeleton
                                height="150px"
                                count={1}
                                className="mb-10"
                            />
                            <Skeleton
                                count={1}
                                width="60%"
                            />
                            <Skeleton
                                count={1}
                            />
                            <Skeleton
                                count={1}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 mb-20 col-sm-6">
                            <Skeleton
                                height="150px"
                                count={1}
                                className="mb-10"
                            />
                            <Skeleton
                                count={1}
                                width="60%"
                            />
                            <Skeleton
                                count={1}
                            />
                            <Skeleton
                                count={1}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SelectonVideoSection;