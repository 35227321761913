import React from 'react';
import './styles/textareaSimple.scss'

const TextareaSimple = (props) => {
    return (
        <div className={`simple_textarea_block ${props.className}`}>
            {
                !props.floatingLabel && (
                    <label htmlFor="">
                        <span className={"label_name"}>{props.inputLabel}  <strong className={props.asterisk ? "asterisk name" : "name"}>{props.asterisk && '*'}</strong></span>
                    </label>
                )}
            <textarea
                className={` ${props.inputClassName}`}
                name={props.name}
                value={props.value}
                id={props.id}
                onChange={props.onchangeCallback}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
                disabled={props.disabled}
                maxLength={props.maxLength}
            />
            {
                props.floatingLabel && (
                    <label htmlFor="">
                        <span className={"label_name"}>{props.inputLabel}  <strong className={props.asterisk ? "asterisk name" : "name"}>{props.asterisk && '*'}</strong></span>
                    </label>
                )}
            {
                props.textCount && (
                    <span className='count-text'>{props.value.length}/{props.maxLength - 1}</span>
                )
            }
            {props.requiredMessage ? (
                <span className="error-message">
                    {props.requiredMessageLabel}
                </span>
            ) : ''
            }
        </div>
    );
};

export default TextareaSimple;
