import React from 'react';
import ModalBootstrap from "../modalBootstrap/ModalBootstrap";
import Button from "../button/Button";
import './DoubleConfirmationModal.scss'
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

function DoubleConfirmationModal(props) {
    const  { t } = useTranslation();
    return (
        <div>
            <ModalBootstrap
                show={props.open}
                handleClose={() => {
                    props.close(false)
                }}
                size="md"
                class={`double-confirmation ${props.modalClassName}`}
            >
                <div className='modal-body'>
                    <span className='delete-icon'><Icon icon={props.icon ? props.icon : 'mingcute:delete-fill'}/></span>
                    <h3>{props?.title ? props?.title : 'Confirm Delete'}</h3>
                    <p>{props.text}</p>
                    <div className="modal-bottom">
                    <Button
                        type="submit"
                        onclickCallback={() => props.close(false)}
                        btnClassName="primary cancel-btn"
                        btnText={'Cancel'}
                        disabled={props.loading}
                    />
                    <Button
                        type="submit"
                        onclickCallback={props.submit}
                        isLoading={props.loading}
                        disabled={props.loading || props.disabled}
                        btnClassName={`${props.className} confirm-btn`}
                        btnText={props.btnText || 'Delete'}
                    />
                </div>
                </div>
               
            </ModalBootstrap>
        </div>
    );
}

export default DoubleConfirmationModal;