const path = {
  dashboard: '/dashboard',
  view_all: '/view-all',
  login: '/auth/login',
  registration: '/auth/registration',
  all_client_videos: '/all-videos',
  client_video_show: '/video-show',
  opt_in: '/opt-in',
  terms_condition: '/terms-condition',
  error400: '/pages/errors/error400',
};

export default path;
