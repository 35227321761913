import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import siteConfig from "../../config/site-config";
import './Header.scss'
import { Icon } from "@iconify/react/dist/iconify.js";
import Sidebar from "../Sidebar/Sidebar";
import Button from "../button/Button";
import path from "../../routes/path";
import AddModuleModal from "../Modals/AddModuleModal/AddModuleModal";
import VideoUploadModal from "../Modals/VideoUploadModal/VideoUploadModal";
const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [sideBar, setSideBar] = useState(false);
    const [addModule, setAddModule] = useState(false);
    const [videoEditData, setVideoEditData] = useState({});
    const [videoUploadModal, setVideoUploadModal] = useState(false);

    const handleAddModule = () => {
        setAddModule(!addModule)
        // setVideoUploadModal(!videoUploadModal)
    }

    const handleVideoUploadModal = () => {
        setVideoUploadModal(!videoUploadModal)
        setVideoEditData({ edit: false, data: {} })
    }
    const handleSideBar = () => {
        setSideBar(!sideBar)
    }

    console.log(location.pathname.slice(1))

    return (
        <>
            <header className="header">
                <div className={`container-fluid ${location.pathname.slice(1) === 'all-videos' || location.pathname.slice(1) === 'video-show' ? 'container' : ''}`}>
                    <div className="row">
                        <div className="col-6 align-self-center">
                            <div className="header-left">
                                {/* <span onClick={handleSideBar} className="menu-icon"><Icon icon={sideBar ? 'system-uicons:cross' : 'ri:menu-4-fill'} /></span> */}
                                <div className="header-logo">
                                    <Link to={`${path.dashboard}?page=1`}>
                                        <img src={siteConfig.company_logo} alt={siteConfig.company_name} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 align-self-center">
                            <div className="menu text-right">
                                <ul>
                                    <li>
                                        <Button
                                            icon2={'tabler:logout'}
                                            btnText={'Log Out'}
                                            btnClassName={'logout-btn'}
                                            onclickCallback={() => { localStorage.clear(); navigate(path.login) }}
                                        />
                                        <div className="mobile-number">
                                            <span><Icon icon={'material-symbols:call'} /></span> <a href="tel:+(870) 587-0800">(870) 587-0800</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-sticky-menu">
                <ul>
                    <li>
                        <NavLink to={`${path.dashboard}?page=1`}>
                            <span><Icon height={26} width={26} icon={'ic:baseline-home'} /></span>
                            <span>Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <a onClick={handleVideoUploadModal}>
                            <span><Icon height={26} width={26} icon={'material-symbols:video-call-sharp'} /></span>
                            <span>Upload video</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={handleAddModule}>
                            <span><Icon height={26} width={26} icon={'material-symbols:add-box-sharp'} /></span>
                            <span>Add module</span>
                        </a>
                    </li>
                </ul>
            </div>
            <Sidebar handleSideBar={handleSideBar} sideBar={sideBar} />
            {
                addModule ?
                    <AddModuleModal
                        handleModalOpenClose={handleAddModule} /> : null
            }
            {
                videoUploadModal ?
                    <VideoUploadModal
                        videoEditData={videoEditData}
                        handleModalOpenClose={handleVideoUploadModal}
                        handleAddModule={handleAddModule} /> : null
            }
        </>
    )
}
export default Header;