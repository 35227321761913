import React from 'react';
import Modal from "react-bootstrap/Modal";
import "./styles/modalBootstrap.scss"
import { Icon } from '@iconify/react';
import Button from '../button/Button';

const ModalBootstrap = (props) => {

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size={props.size}
                dialogClassName={props.class}
            >
                {!props.header ?
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100">{props.title}</Modal.Title>
                        {props.modalHeaderbtn &&
                            <Button
                                btnClassName='video-top-btn'
                                btnText={props.modalHeaderbtnText}
                            />}
                        <div onClick={() => props.handleClose()} className="modal-close-b">
                            <Icon width={28} height={28} icon="system-uicons:cross" />
                        </div>
                    </Modal.Header> : ""
                }
                {props.children}
            </Modal>
        </>
    );
};

export default ModalBootstrap;
