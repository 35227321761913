import React, { useState } from 'react';
import './VideoShareModal.scss';
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap';
import { useFormik } from 'formik';
import InputField from '../../InputField/InputField';
import Button from '../../button/Button';
import { emailRegex, validateAlphaNumericWithSpace, validateNumberOnly } from '../../../helper/CommonFunctions';
import AxiosServices from '../../../networks/AxiosService';
import ApiUrlServices from '../../../networks/ApiUrlServices';
import { toast } from 'react-toastify';

const VideoShareModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const handleRemoveClick = index => {
        const list = [...VideoShareFormik.values.patients];
        list.splice(index, 1);
        VideoShareFormik.setFieldValue('patients', list)
    };
    const handleAddFields = () => {
        VideoShareFormik.setFieldValue('patients', [
            ...VideoShareFormik.values.patients,
            {
                // "name": "",
                "mobile": "",
                // "email": ""
            },
        ])
    };

    const validateVideoShareForm = (values) => {
        const errors = {};
        // const validatePhoneNumberPattern = /^(?:\+1[-.\s]?|1[-.\s]?)?(?:\((\d{3})\)|(\d{3}))[-.\s]?\d{3}[-.\s]?\d{4}$/;
        values.patients.forEach((item, index) => {
            const accessoryKey = `patients_fields_${index}`;
            // if (item.name.trim().length > 40) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].name = "Patient name should be less than 40 characters";
            // }
            // if (item.name.trim().length < 3) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].name = "Patient name should be more than 3 characters";
            // }
            // if (!item.name.trim()) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].name = "Patient name is required";
            // }
            // if (item.name && !validateAlphaNumericWithSpace.test(item.name.trim())) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].name = "Patient name supports alphabets, numbers, dash(-) and underscore(_) only";
            // }
            if (!validateNumberOnly.test(item.mobile.trim())) {
                if (!errors[accessoryKey]) {
                    errors[accessoryKey] = {};
                }
                errors[accessoryKey].mobile = "Invalid mobile number";
            }

            if (item.mobile.trim() && item.mobile.trim().length !== 10) {
                if (!errors[accessoryKey]) {
                    errors[accessoryKey] = {};
                }
                errors[accessoryKey].mobile = "Please enter a valid US mobile number";
            }

            if (!item.mobile.trim()) {
                if (!errors[accessoryKey]) {
                    errors[accessoryKey] = {};
                }
                errors[accessoryKey].mobile = "Mobile number is required";
            }
            // if (!emailRegex.test(item.email.trim())) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].email = "Invalid email address";
            // }
            // if (!item.email.trim()) {
            //     if (!errors[accessoryKey]) {
            //         errors[accessoryKey] = {};
            //     }
            //     errors[accessoryKey].email = "Email is required";
            // }
        });
        return errors;
    }

    const submitVideoShareForm = (values) => {
        setLoading(true);
        const patiensData = values.patients
        const updatedPatients = patiensData.map(patient => {
            if (!patient.mobile.startsWith('+1')) {
                return { ...patient, mobile: `+1${patient.mobile}` };
            }
            return patient;
        });

        // console.log(updatedPatients)
        const payload = {
            "videos_id": props.singleShareVideoId.length > 0 ? props.singleShareVideoId : props.selectedItems,
            "patients": updatedPatients
        }
        AxiosServices.post(ApiUrlServices.SHARE_VIDEO, payload)
            .then((res) => {
                console.log(res.data)
                props.multipleCheckedTopBar()
                toast.success('Video shared successfully');
            })
            .catch((err) => {
                console.log(err)
                const errorMessageCode = err.response.data.status_code
                if (errorMessageCode === 400) {
                    toast.error('Video id is required');
                } else {
                    toast.error('Something went wrong');
                }
            })
            .finally(() => {
                setLoading(false);
                props.handleModalOpenClose();
            })
    }

    const VideoShareFormik = useFormik({
        initialValues: {
            "patients": [
                {
                    // "name": "", 
                    "mobile": "",
                    // "email": "" 
                }
            ]
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validateVideoShareForm,
        onSubmit: submitVideoShareForm
    });


    const handleFocus = (i) => {
        setIsFocused(i);
    };

    const handleBlur = () => {
        setIsFocused(i => i === isFocused ? false : i);
    };

    console.log(isFocused)

    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            title="Share video"
            size={"lg"}
            class='share-video-modal'
        >
            <div className="modal-body">
                <form>
                    {
                        VideoShareFormik.values.patients?.map((param, index) => (
                            <div key={index} className={`row configurable-fields ${index === 0 ? 'fast' : ''}`}>
                                {/* <div className="col-md-6">
                                    <InputField
                                        label={'Patient name'}
                                        placeholder={' '}
                                        type="text"
                                        className="mb-15 floating-label-input"
                                        inputName={`patients.${index}.name`}
                                        maxLength={41}
                                        floatingLabel={true}
                                        whiteSpace={false}
                                        onBlur={VideoShareFormik.patients}
                                        value={VideoShareFormik.values.patients[index]?.name}
                                        onchangeCallback={VideoShareFormik.handleChange}
                                        inputClassName={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.name && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.name ? " is-invalid" : ""}
                                        requiredMessage={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.name && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.name}
                                        requiredMessageLabel={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.name || VideoShareFormik.isSubmitting ? VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.name : ""}
                                    />
                                </div> */}
                                <div className="col-md-12 mobile-number">
                                    <span className='mobile-code'>
                                        {isFocused === index || VideoShareFormik.values.patients[index]?.mobile.length > 0 ? '+1' : ''}
                                    </span>
                                    <InputField
                                        label={'Mobile number'}
                                        placeholder={' '}
                                        type="text"
                                        className="mb-15 floating-label-input"
                                        inputName={`patients.${index}.mobile`}
                                        maxLength={15}
                                        floatingLabel={true}
                                        whiteSpace={false}
                                        onFocus={() => handleFocus(index)}
                                        onBlur={() => {
                                            VideoShareFormik.handleBlur(`patients.${index}.mobile`);
                                            handleBlur(index)
                                        }}
                                        value={VideoShareFormik.values.patients[index]?.mobile}
                                        onchangeCallback={VideoShareFormik.handleChange}
                                        inputClassName={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.mobile && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.mobile ? " is-invalid" : ""}
                                        requiredMessage={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.mobile && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.mobile}
                                        requiredMessageLabel={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.mobile || VideoShareFormik.isSubmitting ? VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.mobile : ""}
                                    />
                                </div>
                                {/* <div className="col-md-12">
                                    <InputField
                                        label={'Email'}
                                        placeholder={' '}
                                        type="text"
                                        className="mb-15 floating-label-input"
                                        inputName={`patients.${index}.email`}
                                        maxLength={40}
                                        floatingLabel={true}
                                        whiteSpace={false}
                                        onBlur={VideoShareFormik.patients}
                                        value={VideoShareFormik.values.patients[index]?.email}
                                        onchangeCallback={VideoShareFormik.handleChange}
                                        inputClassName={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.email && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.email ? " is-invalid" : ""}
                                        requiredMessage={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.email && VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.email}
                                        requiredMessageLabel={VideoShareFormik.touched.patients && VideoShareFormik.touched.patients[index]?.email || VideoShareFormik.isSubmitting ? VideoShareFormik.errors && VideoShareFormik.errors[`patients_fields_${index}`]?.email : ""}
                                    />
                                </div> */}
                                <div className="col-12">
                                    {index !== 0 &&
                                        <div className="remove-btn">
                                            <span onClick={() => handleRemoveClick(index)}>Remove</span>
                                        </div>
                                    }

                                </div>
                            </div>
                        ))
                    }
                    <div className="row video-share-btn">
                        <div className="add-field-btn col-4">
                            <Button
                                btnText={'Add Patient'}
                                icon1={'ion:add-outline'}
                                onclickCallback={handleAddFields}
                                type="button"
                            />
                        </div>
                        <div className='video-share-btn-right col-8'>
                            <Button
                                btnText={'Cancel'}
                                onclickCallback={props.handleModalOpenClose}
                                disabled={loading}
                                type="button"
                                btnClassName="cancel-btn"
                            />
                            <Button
                                btnText={'Send Video'}
                                onclickCallback={VideoShareFormik.handleSubmit}
                                disabled={loading}
                                isLoading={loading}
                                btnClassName={'submit-btn'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </ModalBootstrap>
    )
}

export default VideoShareModal