import { Icon } from '@iconify/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

const sidebarButton = (props) => {
    return (
        <NavLink className={`sidebar-button ${props.className}`} to={props.to}>
            <Icon width={22} height={22} icon={props.icon} />
            {props.buttonText}
        </NavLink>
    )
}

export default sidebarButton
