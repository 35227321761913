import {createSlice} from "@reduxjs/toolkit";

const videoSlice = createSlice({
    name: "videoReducer",
    initialState: {
        moduleAddRender: false,
        videoUploadRender: false,
    },
    reducers: {
        setModuleAddRender(state, action) {
            state.moduleAddRender = action.payload
        },
        setVideoUploadRender(state, action) {
            state.videoUploadRender = action.payload
        },
    }
});

export const videoAction = videoSlice.actions;

export default videoSlice;
