import React, { Fragment, useEffect, useRef, useState } from 'react'
import './VideoUploadModal.scss';
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import { useFormik } from 'formik';
import InputField from '../../InputField/InputField';
import ReactSelectField from '../../ReactSelectField/ReactSelectField';
import Button from '../../button/Button';
import { Icon } from '@iconify/react/dist/iconify.js';
import TextareaSimple from '../../textareaSimple/textareaSimple';
import AxiosServices from '../../../networks/AxiosService';
import ApiUrlServices from '../../../networks/ApiUrlServices';
import { bucket_base_url } from '../../../config/config';
import {
    formatDuration,
    genericImageValidatorResizer,
    removeGarbageCharOnFileName,
    validateAlphaNumericWithSpace
} from '../../../helper/CommonFunctions';
import ImgCropper from '../../cropper/cropper';
import { Storage } from "aws-amplify";
import ProgressBar from "../../progressBar/ProgressBar";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { videoAction } from '../../../store/videoSlice';
import CustomLoader from '../../customLoader';

const VideoUploadModal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [getEditDataLoading, setGetEditDataLoading] = useState(false)
    const [getModuleOptionLoader, setGetModuleOptionLoader] = useState(false)
    const [videoUploading, setVideoUploading] = useState(false)
    const [isFocused, setIsFocused] = useState(false);
    const [moduleList, setModuleList] = useState([]);

    const inputRef = useRef(null);
    const videoRef = useRef(null);
    const [imageError, setImageError] = useState("");
    const [imageName, setImageName] = useState("");
    const [imageProfile, setImageProfile] = useState({});
    const [thumbLoading, setThumbLoading] = useState(false);
    const [uploadedPercentage, setUploadPercent] = useState(0);
    const [cropImageModal, setCropImageModal] = useState(false);
    const [showImgUploadDiv, setShowImgUploadDiv] = useState(false);

    const moduleAddRender = useSelector((state) => state.videoReducer.moduleAddRender);

    const thumbResolution = { width: 600, height: 340 };
    const initialPayload = {
        page: 1,
        limit: 1000
    }

    // console.log(props.videoEditData, 'videoEditData')

    useEffect(() => {
        getAllModules()
    }, [moduleAddRender])

    const getAllModules = () => {
        setGetModuleOptionLoader(true)
        if (props.videoEditData.edit) setGetEditDataLoading(true)
        AxiosServices.get(ApiUrlServices.CREATE_GET_MODULE, initialPayload)
            .then(async (res) => {
                console.log(res.data.data.modules)
                const modules = res.data.data.modules;
                const module_list = await modules.map((module) => {
                    return {
                        label: module.name,
                        value: module.id
                    }
                })
                setModuleList(module_list)

                if(moduleAddRender){
                    await VideoUploadFormFormik.setFieldValue('module_name', module_list[0])
                }

                // set field value for edit
                if (props.videoEditData.edit) {
                    const videoEditData = props.videoEditData.data;
                    const selectedOption = module_list.find(item => item.value === videoEditData.module_id);
                    await VideoUploadFormFormik.setFieldValue('module_name', selectedOption)
                    await VideoUploadFormFormik.setFieldValue('video_name', videoEditData.title)
                    await VideoUploadFormFormik.setFieldValue('thumbnail_img', videoEditData.thumbnail_path)
                    await VideoUploadFormFormik.setFieldValue('thumbnail_video', videoEditData.video_file_path)
                    await VideoUploadFormFormik.setFieldValue('description', videoEditData.description)
                }
            })
            .catch((err) => {
                console.log(err)
                setGetModuleOptionLoader(false)
                if (props.videoEditData.edit) setGetEditDataLoading(false)
                toast.error('Failed to get module list')
            })
            .finally(() => {
                setGetModuleOptionLoader(false)
                if (props.videoEditData.edit) setGetEditDataLoading(false)
                dispatch(videoAction.setModuleAddRender(false))
            })
    }

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const validationVideoUploadForm = (values) => {
        const errors = {}
        if (!values.module_name) {
            errors.module_name = "Module is required"
        }
        if (!values.video_name.trim()) {
            errors.video_name = "Title is required"
        } else if (values.video_name.trim().length > 50) {
            errors.video_name = "Title should be maximum 50 characters"
        } else if (values.video_name.trim() && values.video_name.trim().length < 3) {
            errors.video_name = "Title should be at least 3 characters"
        } else if (values.video_name.trim() && !validateAlphaNumericWithSpace.test(values.video_name.trim())) {
            errors.video_name = "Title supports number and alphabet only"
        }
        if (!values.description.trim()) {
            errors.description = "Description is required"
        } else if (values.description.trim().length > 1200) {
            errors.description = "Description should be maximum 1200 characters"
        } else if (values.description.trim() && values.description.trim().length < 3) {
            errors.description = "Description should be minimum 3 characters"
        }

        if (values.thumbnail_img === '') {
            if (imageError) {
                if (imageError !== "") {
                    errors.thumbnail_img = imageError;
                }
            } else if (!values.thumbnail_img.trim() && !thumbLoading) {
                errors.thumbnail_img = "Thumbnail is required"
            }
        }

        if (!values.thumbnail_video.trim()) {
            errors.thumbnail_video = "Video is required"
        }

        console.log(errors)
        return errors
    }

    const submitVideoUploadForm = (values) => {
        setLoading(true)
        const payload = {
            module_id: values.module_name.value,
            title: values.video_name.trim(),
            thumbnail_path: values.thumbnail_img.trim(),
            video_file_path: values.thumbnail_video.trim(),
            description: values.description.trim(),
            duration: values.duration
        }
        if (props.videoEditData.edit) {
            AxiosServices.put(ApiUrlServices.UPDATE_VIDEO(props.videoEditData.data.id), payload)
                .then((res) => {
                    console.log(res)
                    setLoading(false)
                    props.setVideoDetailsData(res.data.data.video)
                    props.handleModalOpenClose()
                    dispatch(videoAction.setVideoUploadRender(true))
                    toast.success('Video updated successfully')
                })
                .catch((err) => {
                    console.log(err)
                    const errorMessageCode = err.response.data.status_code
                    if (errorMessageCode === 404) {
                        toast.error('Video not found')
                    } else {
                        toast.error('Opps! Something went wrong!')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            AxiosServices.post(ApiUrlServices.CREATE_VIDEO, payload)
                .then((res) => {
                    console.log(res)
                    setLoading(false)
                    props.handleModalOpenClose()
                    dispatch(videoAction.setVideoUploadRender(true))
                    toast.success('Video uploaded successfully.')
                })
                .catch((err) => {
                    console.log(err)
                    const errorMessageCode = err.response.data.status_code
                    if (errorMessageCode === 400) {
                        toast.error('Video all field is required')
                    } else {
                        toast.error('Opps! Something went wrong!')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const VideoUploadFormFormik = useFormik({
        initialValues: {
            module_name: '',
            video_name: '',
            thumbnail_img: '',
            thumbnail_video: '',
            description: '',
            duration: '',
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validationVideoUploadForm,
        onSubmit: submitVideoUploadForm
    })

    const handleImageUpload = async (e) => {
        let file = e
        const imageSize = parseFloat(file.size / 1024).toFixed(2);
        console.log(imageSize)
        let thumb_url = '';
        if (file.name) {
            setThumbLoading(true)
            let pathUrl = `video_thumb/${Date.now()}_${removeGarbageCharOnFileName(file.name)}`;
            await Storage.put(pathUrl, file,
                {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${Math.round(Number(progress.loaded / progress.total) * 100)}`);
                        setUploadPercent(Math.round(Number(progress.loaded / progress.total) * 100));
                    },
                })
                .then(res => {
                    console.log('thumb_url', res)
                    thumb_url = "public/" + res.key;
                    VideoUploadFormFormik.setValues((values) => {
                        values.thumbnail_img = thumb_url;
                        return values;
                    });
                    setThumbLoading(false)
                }).catch(err => {
                    console.log(err)
                    setThumbLoading(false)
                })
        }
    }

    const handleVideoUpload = async (e) => {
        setUploadPercent(0)
        let file = e.target.files[0]

        // handle video hour
        let video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.addEventListener('loadedmetadata', function () {
            let durationFormatted = formatDuration(Math.floor(video.duration));
            console.log(video.duration, 'duration')
            VideoUploadFormFormik.setFieldValue('duration', durationFormatted)
            URL.revokeObjectURL(this.src);
        });

        const videoSize = parseFloat(file.size / 1024 / 1024).toFixed(2);
        console.log(videoSize)
        let video_url = '';

        console.log(file.name)
        console.log(file.type)

        if (file.name && (file.name.toLowerCase().endsWith('.mp4') || file.name.toLowerCase().endsWith('.mpeg') || file.name.toLowerCase().endsWith('.avi') || file.name.toLowerCase().endsWith('.mov') || file.name.toLowerCase().endsWith('.flv') || file.name.toLowerCase().endsWith('.wmv') || file.name.toLowerCase().endsWith('.3gp') || file.name.toLowerCase().endsWith('.mkv') || file.name.toLowerCase().endsWith('.hevc') || file.name.toLowerCase().endsWith('.webm'))) {
            setVideoUploading(true)
            let pathUrl = `video_thumb/${Date.now()}_${removeGarbageCharOnFileName(file.name)}`;
            await Storage.put(pathUrl, file,
                {
                    progressCallback(progress) {
                        console.log(`Uploaded: ${Math.round(Number(progress.loaded / progress.total) * 100)}`);
                        setUploadPercent(Math.round(Number(progress.loaded / progress.total) * 100));
                    },
                })
                .then(res => {
                    console.log('thumb_url', res)
                    video_url = "public/" + res.key;
                    VideoUploadFormFormik.setValues((values) => {
                        values.thumbnail_video = video_url;
                        return values;
                    });
                    setVideoUploading(false)
                }).catch(err => {
                    console.log(err)
                    setVideoUploading(false)
                })
        } else {
            toast.error('Please upload a supported video file')
        }
    }

    const onResizeImage = (image, name) => {
        setImageName(name);
        setImageProfile(image);
        setCropImageModal(true);
    }

    const setImageErrors = (errorMessage) => {
        setImageError(errorMessage);
        VideoUploadFormFormik.setErrors({
            ...VideoUploadFormFormik.errors,
            "thumbnail_img": errorMessage,
        });
    };

    const imageUploadBlock = () => {
        return <div className="img-upload-main">
            <div className="img-upload-sub">
                <span className='file-icon'><Icon icon={'gridicons:add-image'} /></span>
                <p className='drag-text'><span>Upload thumbnail</span></p>
                <span>Supported larger than <span className="text-primary p-0">{thumbResolution.width} X {thumbResolution.height}</span> and Formats are <span className="text-primary">.jpg, .JPG, .png, .PNG, .jpeg, .JPEG</span></span>
                <input
                    onBlur={VideoUploadFormFormik.handleBlur}
                    id="thumbnail_img"
                    type="file"
                    name='thumbnail_img'
                    className='file_upload'
                    onChange={e => {
                        setImageError("");
                        const config = {
                            height: thumbResolution.height,
                            width: thumbResolution.width
                        }
                        genericImageValidatorResizer(
                            e.target.files[0],
                            config,
                            setImageErrors,
                            false,
                            handleImageUpload,
                            false,
                            e,
                            onResizeImage
                        )
                        e.target.value = null; // Reset the value of the file input
                    }}
                    ref={inputRef}
                    accept=".jpg, .JPG, .PNG, .png, .JPEG, .jpeg"
                />
            </div>
        </div>
    }

    const videoUploadBlock = () => {
        return <div className="img-upload-main">
            <div className='img-upload-sub'>
                <span className='file-icon'><Icon icon={'ic:baseline-upload'} /></span>
                <p className='drag-text'>Browse video to upload</p>
                <span>Supported Formats are <span className="text-primary p-0">mp4, mpeg, 3gp, avi, mov, wmv, flv, mkv, hevc, webm</span></span>
                <input
                    onBlur={VideoUploadFormFormik.handleBlur}
                    id="thumbnail_video"
                    type="file"
                    name='thumbnail_video'
                    className='file_upload'
                    onChange={e => {
                        handleVideoUpload(e)
                    }}
                    // ref={videoRef}
                    accept=".mp4, .MP4, .mpeg, .MPEG, .avi, .AVI, .mov, .MOV, .flv, .FLV, .wmv, .WMV, .3gp, .3GP, .mkv, .MKV, .webm, .WEBM, .hevc, .HEVC"
                />
            </div>
        </div>
    }

    return (
        <>
            <ModalBootstrap
                show={props.handleModalOpenClose}
                handleClose={props.handleModalOpenClose}
                title={props.videoEditData.edit ? 'Update video' : 'Upload video'}
                size={"lg"}
                class='video-upload-modal'
            >
                <div className="modal-body">
                    <CustomLoader Loader={getEditDataLoading} />
                    <div className="row">
                        <div className="col-sm-9 col-10">
                            <ReactSelectField
                                id="module_name"
                                className="mb-15 floating-label-input"
                                labelText={"Select module"}
                                name="module_name"
                                placeholder={" "}
                                loading={getModuleOptionLoader}
                                asterisk={false}
                                whiteSpace={false}
                                options={moduleList}
                                isClearable={true}
                                onFocus={handleFocus}
                                isFocused={isFocused}
                                onBlur={(e) => {
                                    VideoUploadFormFormik.handleBlur(e)
                                    handleBlur()
                                }}
                                value={VideoUploadFormFormik.values.module_name}
                                noOptionsMessage={() => "No module available."}
                                onchangeCallback={(e) => {
                                    VideoUploadFormFormik.setFieldValue('module_name', e)
                                }}
                                inputClassName={VideoUploadFormFormik.touched.module_name && VideoUploadFormFormik.errors.module_name ? " is-invalid" : ""}
                                requiredMessage={VideoUploadFormFormik.touched.module_name && VideoUploadFormFormik.errors.module_name}
                                requiredMessageLabel={VideoUploadFormFormik.touched.module_name || VideoUploadFormFormik.isSubmitting ? VideoUploadFormFormik.errors.module_name : ""}
                            />
                        </div>
                        <div className="col-sm-3 col-2 ps-0">
                            <Button
                                icon1={'ion:add-outline'}
                                btnText={'Add Module'}
                                onclickCallback={props.handleAddModule}
                                btnClassName={'btn-outline'}
                            />
                        </div>
                        <div className="col-12">
                            <InputField
                                placeholder={" "}
                                type="text"
                                label="Title"
                                inputType='text'
                                className="mb-15 floating-label-input"
                                inputName="video_name"
                                floatingLabel={true}
                                asterisk={false}
                                whiteSpace={false}
                                maxLength={51}
                                onBlur={VideoUploadFormFormik.handleBlur}
                                value={VideoUploadFormFormik.values.video_name}
                                onchangeCallback={VideoUploadFormFormik.handleChange}
                                inputClassName={VideoUploadFormFormik.touched.video_name && VideoUploadFormFormik.errors.video_name ? " is-invalid" : ""}
                                requiredMessage={VideoUploadFormFormik.touched.video_name && VideoUploadFormFormik.errors.video_name}
                                requiredMessageLabel={VideoUploadFormFormik.touched.video_name || VideoUploadFormFormik.isSubmitting ? VideoUploadFormFormik.errors.video_name : ""}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="thumbnail-img">
                                <label className='lable'>Video</label>
                                {
                                    videoUploading ?
                                        <div className="d-block">
                                            <span className='thumbnail-img-loader'>
                                                <Icon icon={'eos-icons:loading'} />
                                            </span>
                                            <div className="mt-2"><ProgressBar progress={uploadedPercentage} /></div>
                                        </div>
                                        :
                                        <div
                                            className={`upload-image ${VideoUploadFormFormik.values.thumbnail_video === '' ? 'no-image' : ''}`}
                                        >
                                            {videoUploadBlock()}

                                            {
                                                VideoUploadFormFormik.values.thumbnail_video !== "" &&
                                                <div className="img-preview-block">
                                                    <video width="100%" height="200" controls className="">
                                                        <source src={`${bucket_base_url}${VideoUploadFormFormik.values.thumbnail_video}`} type="video/mp4" />
                                                    </video>
                                                </div>
                                            }

                                        </div>

                                }

                                <span className={`${videoUploading ? 'd-none' : ''}`}>
                                    {
                                        VideoUploadFormFormik.touched.thumbnail_video && VideoUploadFormFormik.errors.thumbnail_video ?
                                            <span className="error-message">
                                                {VideoUploadFormFormik.touched.thumbnail_video || VideoUploadFormFormik.isSubmitting ? VideoUploadFormFormik.errors.thumbnail_video : ""}
                                            </span> : ''
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="thumbnail-img">
                                <label className='lable'>Thumbnail</label>
                                {
                                    thumbLoading ?
                                        <span className='thumbnail-img-loader'><Icon icon={'eos-icons:loading'} /></span>
                                        :
                                        <div
                                            className={`upload-image ${VideoUploadFormFormik.values.thumbnail_img === '' ? 'no-image' : ''}`}
                                        >
                                            {imageUploadBlock()}

                                            {
                                                VideoUploadFormFormik.values.thumbnail_img !== "" &&
                                                <div className="img-preview-block">
                                                    <img src={`${bucket_base_url}${VideoUploadFormFormik.values.thumbnail_img}`}
                                                        alt="thumb" />
                                                </div>
                                            }

                                        </div>
                                }

                                <span className={`${thumbLoading ? 'd-none' : ''}`}>
                                    {VideoUploadFormFormik.touched.thumbnail_img &&
                                        VideoUploadFormFormik.errors.thumbnail_img ?
                                        <span className="error-message">
                                            {VideoUploadFormFormik.touched.thumbnail_img || VideoUploadFormFormik.isSubmitting ? VideoUploadFormFormik.errors.thumbnail_img : ""}
                                        </span> :
                                        ''
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <TextareaSimple
                                id="description"
                                inputLabel="Description"
                                className="mb-15 floating-label-input"
                                onBlur={VideoUploadFormFormik.handleBlur}
                                name="description"
                                placeholder=" "
                                asterisk={false}
                                floatingLabel={true}
                                textCount={true}
                                maxLength={1201}
                                value={VideoUploadFormFormik.values.description}
                                onchangeCallback={VideoUploadFormFormik.handleChange}
                                inputClassName={VideoUploadFormFormik.touched.description && VideoUploadFormFormik.errors.description ? " is-invalid" : ""}
                                requiredMessage={
                                    VideoUploadFormFormik.touched.description &&
                                    VideoUploadFormFormik.errors.description
                                }
                                requiredMessageLabel={
                                    VideoUploadFormFormik.touched.description ||
                                        VideoUploadFormFormik.isSubmitting
                                        ? VideoUploadFormFormik.errors.description
                                        : ""
                                }
                            />
                        </div>
                        <div className="col-12">
                            <div className='upload-video-btn'>
                                <Button
                                    btnText={'Cancel'}
                                    onclickCallback={props.handleModalOpenClose}
                                    disabled={loading || videoUploading || thumbLoading || getModuleOptionLoader}
                                    type="button"
                                    btnClassName="cancel-btn"
                                />
                                <Button
                                    btnText={props.videoEditData.edit ? 'Update' : 'Create'}
                                    onclickCallback={VideoUploadFormFormik.handleSubmit}
                                    disabled={loading || videoUploading || thumbLoading || getModuleOptionLoader}
                                    isLoading={loading}
                                    btnClassName="submit-btn"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBootstrap>
            {
                cropImageModal ?
                    <ModalBootstrap
                        show={cropImageModal}
                        handleClose={setCropImageModal}
                        title="Resize image"
                        size={"md"}
                    >
                        <ImgCropper
                            inputRef={inputRef}
                            onClose={setCropImageModal}
                            imageFile={imageProfile}
                            imageName={imageName}
                            aspectRatio={thumbResolution.width / thumbResolution.height}
                            // cropShape="round"
                            cropImgSize={{ width: thumbResolution.width, height: thumbResolution.height }}
                            callback={handleImageUpload}

                        />
                    </ModalBootstrap>
                    : null
            }
        </>
    )
}

export default VideoUploadModal