import React from 'react';
import path from "./path";
import DefaultLayout from "../layouts/DefaultLayout";
import UserSideLayout from "../layouts/UserSideLayout";
import AuthLayout from "../layouts/AuthLayout"
import siteConfig from "../config/site-config";

let DelayTime = siteConfig.lazy_suspense_delay;
let SiteName = `| ${siteConfig.company_name}`;

const Login = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Login" */ "../pages/auth/login"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Signup = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Signup" */ "../pages/auth/signup"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Default = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Default" */ "../pages/dashboard/default"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const Home = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Home" */ "../pages/home/Home"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ViewAllVideo = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "All Video List" */ "../pages/ViewAllVideo/ViewAllVideo"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ClientAllVideos = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "All Client Viideos" */ "../pages/ClientAllVideos/ClientAllVideos"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ClientVideosShow = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Show Client video" */ "../pages/ClientVideosShow/ClientVideosShow"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ClientOtpIn = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Show Client video" */ "../pages/ClientOtpIn/ClientOtpIn"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

const ClientTermsCondition = React.lazy(() => {
    return Promise.all([import(/*webpackChunkName: "Show Client video" */ "../pages/ClientTermsCondition/ClientTermsCondition"), new Promise(resolve => setTimeout(resolve, DelayTime))]).then(([moduleExports]) => moduleExports);
});

export const private_routes = [
    { path: `${process.env.PUBLIC_URL}${path.dashboard}`, Component: <Home pageTitle={`Dashboard ${SiteName}`} />, Layout: <DefaultLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.view_all}`, Component: <ViewAllVideo pageTitle={`View All ${SiteName}`} />, Layout: <DefaultLayout /> },
]

export const public_routes = [
    { path: `${process.env.PUBLIC_URL}${path.login}`, Component: <Login pageTitle={`Login ${SiteName}`} />, Layout: <AuthLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.registration}`, Component: <Signup pageTitle={`Sign Up ${SiteName}`} />, Layout: <AuthLayout /> },
]

// ************ Example for public private route *********** //
export const public_private_routes = [
    { path: `${process.env.PUBLIC_URL}${path.all_client_videos}`, Component: <ClientAllVideos pageTitle={`All Videos ${SiteName}`} />, Layout: <UserSideLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.client_video_show}`, Component: <ClientVideosShow pageTitle={`Videos ${SiteName}`} />, Layout: <UserSideLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.opt_in}`, Component: <ClientOtpIn pageTitle={`Opt In ${SiteName}`} />, Layout: <UserSideLayout /> },
    { path: `${process.env.PUBLIC_URL}${path.terms_condition}`, Component: <ClientTermsCondition pageTitle={`Terms & Conditions ${SiteName}`} />, Layout: <UserSideLayout /> },
]
