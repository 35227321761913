import { cloudfront_base_url } from "../config/config";

export function pageTitle(title) {
    document.title = title
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateAlphabetOnly = /^[A-Za-z ]+$/;
export const validateNumberOnly = /^[+0-9]+$/;
export const validateAlphaNumericWithSpace = /^[a-zA-Z0-9-_ ]+$/;

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const returnNumberByLang=(num)=>{
    let lang = localStorage.getItem("i18nextLng");
    if (lang === 'en' || lang ==='en-US'){
        return num
    }
    else if(lang==='bn'){
        return englishToBengleNumber(num);
    }
}

export const handleDownloadVideo = (Url) => {
    const url = cloudfront_base_url + Url;
    const link = document.createElement('a');
    link.href = url;
    link.download = 'sample-mpeg-file.mp4';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const englishToBengleNumber=(num)=>{
    let numArray = Number(num).toString().split('');
    let newArray=[];
    numArray.forEach(n=>{
        if(n==='0') newArray.push('০')
        if(n==='1') newArray.push('১')
        if(n==='2') newArray.push('২')
        if(n==='3') newArray.push('৩')
        if(n==='4') newArray.push('৪')
        if(n==='5') newArray.push('৫')
        if(n==='6') newArray.push('৬')
        if(n==='7') newArray.push('৭')
        if(n==='8') newArray.push('৮')
        if(n==='9') newArray.push('৯')
        if(n==='.') newArray.push('.')
    })
    return newArray.join('')
}

export const isVideo = (value) => {
    let new_value = value.split('.');

    const extension = new_value[new_value.length - 1].toLocaleLowerCase();
    let videoTypes = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm', 'mkv', 'm3u8'];
    if (videoTypes.indexOf(extension) !== -1) {
        return true;
    }
    return false;
}

function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const removeGarbageCharOnFileName = (fileName) => {
    const arr = fileName.split(".");
    const ext = arr.pop();

    let name = arr.join(".");
    const filterData = name.replace(/[^a-zA-Z0-9-_]/g, '');

    const finalStr = `${filterData}${generateString(8)}.${ext}`;
    return finalStr;
}

const fileFormatValidator = (name, formatArr) => {
    let flag = false;
    formatArr.map(format => {
        if (name.includes(format)) {
            flag = true;
        }
    })

    return flag;
}

export const fileSizeValidator = (size, type, name, formatArr = []) => {
    // const fileSizeMB = Math.round((size / 1024 / 1024)); // in MB
    const fileSizeKB = Math.round((size / 1024)); // in KB

    const baseFileSizeFormat = {
        "video": 500,
        "attachment": 50,
        "preview": 500,
        "image": 300
    }

    const filetype = {
        "video": [".mp4", ".m4v"],
        "attachment": [".zip", ".rar", ".tar"],
        "preview": [".mp4", ".m4v"],
        "image": ["image"]
    }

    if (name) {
        const formArr = formatArr.length ? formatArr : filetype[type];
        if (!fileFormatValidator(name, formArr)) {
            return "format_mismatch";
        }
    }

    if (!baseFileSizeFormat[type]) {
        return false;
    }

    if (fileSizeKB > baseFileSizeFormat[type]) {
        return false;
    }

    return true;
}

export const validateImageSize2 = async (file, cb) => {

    let img = new Image()
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
        cb(img)
    }
}

export const genericImageValidatorResizer = (file, config, setError, errObj, setImage, imageObj, e, onResizeImage, isNullErrorObj) => {

    const resolveAndSetErrors = (error) => {
        if (errObj) {
            setError(prev => ({
                ...prev,
                [errObj]: error
            }))
        } else {
            setError(error)
        }
    }

    const resolveAndSetImage = (editedFile) => {
        if (imageObj) {
            setImage(prev => ({
                ...prev,
                [imageObj]: editedFile
            }))
        } else {
            setImage(editedFile);
        }
    }

    const validation = fileSizeValidator(file.size, "image", file.type);
    if (validation === true) {
        validateImageSize2(file, (img) => {
            if (img.height >= config.height && img.width >= config.width) {
                resolveAndSetErrors("");
                validateImageSize2(file, async (img) => {
                    if (img.height > config.height || img.width > config.width) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.addEventListener("load", () => {
                            onResizeImage(reader.result, file.name);
                        });

                        return;
                    } else {
                        resolveAndSetImage(file);
                    }
                })
            } else {
                e.target.value = "";
                if (!isNullErrorObj) {
                    setImage({});
                } else {
                    setImage(null);
                }
                resolveAndSetErrors(`Image is too small. Please  upload larger image than ${config.width}X${config.height} resolution.`);
            }
        })
    } else if (validation === false) {
        if (!isNullErrorObj) {
            setImage({});
        } else {
            setImage(null);
        }
        resolveAndSetErrors("Please select an image less than 300 kb");
    } else if (validation === "format_mismatch") {
        e.target.value = "";
        resolveAndSetErrors(`Please select an image`)
    }
}

export function formatDuration(duration) {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - (hours * 3600)) / 60);
    let seconds = duration - (hours * 3600) - (minutes * 60);

    // Pad to two digits
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    seconds = seconds.toString().padStart(2, '0');

    // If hours are '00', return only minutes and seconds
    if (hours === '00') {
        return minutes + ':' + seconds;
    } else {
        return hours + ':' + minutes + ':' + seconds;
    }
}

export function modifyURL(url) {
    url = url.replace(/\.(mp4|MP4|mpeg|MPEG|avi|AVI|mov|MOV|wmv|WMV|3gp|3GP|flv|FLV|mkv|MKV|webm|WEBM|hevc|HEVC|)$/i, '.m3u8');
    // Find the position of the last slash
    let lastSlashIndex = url.lastIndexOf('/');

    // Insert '/hls' after the last slash
    return url.slice(0, lastSlashIndex) + '/hls' + url.slice(lastSlashIndex);
}