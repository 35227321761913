import React, { useState } from 'react'
import './AddModuleModal.scss';
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import InputField from '../../InputField/InputField';
import { useFormik } from 'formik';
import Button from '../../button/Button';
import AxiosServices from '../../../networks/AxiosService';
import ApiUrlServices from '../../../networks/ApiUrlServices';
import { toast } from 'react-toastify';
import { validateAlphaNumericWithSpace } from '../../../helper/CommonFunctions';
import { videoAction } from '../../../store/videoSlice';
import { useDispatch } from 'react-redux';

const AddModuleModal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    const validationAddModuleForm = (values) => {
        const errors = {};
        if (!values.module_name.trim()) {
            errors.module_name = "Module name is required"
        } else if (values.module_name.trim().length > 25) {
            errors.module_name = "Module name should be less than 25 characters"
        } else if (values.module_name.trim() && values.module_name.trim().length < 3) {
            errors.module_name = "Module name should be more than 3 characters"
        } else if (values.module_name.trim() && !(/([a-zA-Z])+([ -~])*/).test(values.module_name.trim())) {
            errors.module_name = "Module name must contain at least an alphabet"
        }
        return errors;
    }

    const submitAddModuleForm = (values) => {
        setLoading(true)
        const payload = {
            "name": values.module_name.trim()
        }
        AxiosServices.post(ApiUrlServices.CREATE_GET_MODULE, payload)
        .then((res) => {
            console.log(res.data)
            toast.success('Module created successfully')
            props.handleModalOpenClose()
        })
        .catch((err) => {
            console.log(err)
            const errorMessageCode = err.response.data.status_code
            if(errorMessageCode === 409){
                toast.error('Module already exists with this name')
            }else if(errorMessageCode === 400){
                toast.error('Module name is required')
            } else{
                toast.error('Failed to create module')
            }
        })
        .finally(() => {
            setLoading(false)
            dispatch(videoAction.setModuleAddRender(true))
        })
    }

    const AddModuleFormFormik = useFormik({
        initialValues: {
            module_name: ''
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validationAddModuleForm,
        onSubmit: submitAddModuleForm
    })

    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            title="Module"
            size={"md"}
            class='add-module-modal'
        >
            <div className="modal-body">
                <form onSubmit={AddModuleFormFormik.handleSubmit}>
                <InputField
                    label={'Module name'}
                    placeholder={' '}
                    type="text"
                    className="mb-10 floating-label-input"
                    inputName="module_name"
                    asterisk={false}
                    whiteSpace={false}
                    floatingLabel={true}
                    maxLength={26}
                    onBlur={AddModuleFormFormik.handleBlur}
                    value={AddModuleFormFormik.values.module_name}
                    onchangeCallback={AddModuleFormFormik.handleChange}
                    inputClassName={AddModuleFormFormik.touched.module_name && AddModuleFormFormik.errors.module_name ? " is-invalid" : ""}
                    requiredMessage={AddModuleFormFormik.touched.module_name && AddModuleFormFormik.errors.module_name}
                    requiredMessageLabel={AddModuleFormFormik.touched.module_name || AddModuleFormFormik.isSubmitting ? AddModuleFormFormik.errors.module_name : ""}
                />
                <div className='add-module-btn'>
                    <Button
                        btnText={'Cancel'}
                        onclickCallback={props.handleModalOpenClose}
                        disabled={loading}
                        type="button"
                        btnClassName="cancel-btn"
                    />
                    <Button
                        btnText={'Save'}
                        onclickCallback={AddModuleFormFormik.handleSubmit}
                        disabled={loading || !AddModuleFormFormik.isValid || !AddModuleFormFormik.dirty}
                        isLoading={loading}
                        btnClassName="submit-btn"
                        type="submit"
                    />
                </div>
                </form>
            </div>
        </ModalBootstrap>
    )
}

export default AddModuleModal