import React from 'react'
import './MultipleChecked.scss';
import { Icon } from '@iconify/react/dist/iconify.js';
import Button from '../button/Button';

const MultipleChecked = (props) => {
    return (
        <>
            {/* <div className={`multiple-checked-overlay ${props.selectedItems.length > 0 && 'active'}`}></div> */}
            <div className={`multiple-checked-container ${props.selectedItems.length > 0 && 'active'}`}>
                <div className="multiple-checked">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="multiple-checked-left">
                                <span onClick={() => props.multipleCheckedTopBar()} className='cross-icon'><Icon icon={'akar-icons:cross'} /></span>
                                <p className='select-checkbox'>
                                    {props.selectedItems.length} Selected
                                </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-8 col-md-6 col-sm-5 col-4 text-center">
                            <div className="multiple-checked-middle">
                                <span className='select-checkbox'>
                                    <Button
                                            icon1={shareIcon}
                                            // isLoading={multipleHandwritingsAudioDownloader}
                                            onclickCallback={() => handleshareWithMemberModalOpen(selectedItems, 'user')}
                                            btnText={t('modal_dropdown_content.share')}
                                            btnClassName='button'
                                        />
                                </span>
                                <span className='select-checkbox'>
                                    <Button
                                            icon1={download}
                                            isLoading={multipleHandwritingsAudioDownloader}
                                            onclickCallback={multipleHandwritingsAudioDownload}
                                            btnText={t('modal_dropdown_content.download')}
                                            btnClassName='button'
                                        />
                                </span>
                                <span className='select-checkbox'>
                                        <Button
                                            icon1={delet}
                                            btnText={t('modal_dropdown_content.delete')}
                                            onclickCallback={handleMultipleHandwritingsAudiosDeleteConfirmation}
                                            isLoading={multipleFileDeleteLoader}
                                            btnClassName='button'
                                        />
                                    </span>
                            </div>
                        </div> */}
                        <div className="col-6 text-right">
                            <div className="multiple-checked-right">
                                <Button
                                    btnText={'Share'}
                                    icon1={'mdi:share'}
                                    onclickCallback={() => props.handleVideoShareModal()}
                                    btnClassName='share'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultipleChecked