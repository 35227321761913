import React, { useEffect, useState } from 'react'
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import { useLocation } from 'react-router-dom';
import AxiosServices from '../../../networks/AxiosService';
import ApiUrlServices from '../../../networks/ApiUrlServices';
import CustomLoader from '../../customLoader';
import CustomCheckBox from '../../CustomCheckBox/CustomCheckBox';
import InputField from '../../InputField/InputField';
import Button from '../../button/Button';
import { useFormik } from 'formik';
import path from '../../../routes/path';
import { toast } from 'react-toastify';

const OtpInUpdateModal = (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const shared_id = params.get('shared_id');
    const [loading, setLoading] = useState(false);
    const [getLoadingPatientData, setGetLoadingPatientData] = useState(false)

    useEffect(() => {
        getOtpInData()
    }, [])

    const getOtpInData = () => {
        setGetLoadingPatientData(true)
        AxiosServices.get(ApiUrlServices.GET_PATIENT(shared_id))
            .then(response => {
                console.log(response)
                const patientData = response?.data?.data?.patient
                // OtpInFormik.setFieldValue('name', patientData?.name)
                // OtpInFormik.setFieldValue('email', patientData?.email)
                OtpInFormik.setFieldValue('mobile', patientData?.mobile)
                // OtpInFormik.setFieldValue('email_status', patientData?.email_status)
                OtpInFormik.setFieldValue('mobile_status', patientData?.mobile_status)
                setGetLoadingPatientData(false)
            })
            .catch(error => {
                console.log(error)
                setGetLoadingPatientData(false)
            })
    }

    const submitOtpInForm = (values) => {
        setLoading(true)
        const payload = {
            // name: values.name,
            mobile: values.mobile,
            // email: values.email,
            mobile_status: values.mobile_status,
            // email_status: values.email_status
        }
        AxiosServices.put(ApiUrlServices.OTP_IN, payload)
            .then(response => {
                console.log(response)
                setLoading(false)
                props.handleModalOpenClose()
                toast.success('Settings updated successfully')
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    const OtpInFormik = useFormik({
        initialValues: {
            // "name": "", 
            "mobile": "",
            // "email": "", 
            "mobile_status": false,
            // "email_status": false 
        },
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: submitOtpInForm
    });

    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            title={'Update Settings'}
            size={"md"}
            class='opt-in-update-modal'
        >
            <div className="modal-body">
                <CustomLoader Loader={getLoadingPatientData} />
                <div className={`row`}>
                    {/* <div className="col-md-12">
                        <InputField
                            label={'Patient name'}
                            placeholder={' '}
                            type="text"
                            className="mb-15 floating-label-input"
                            inputName={`name`}
                            maxLength={41}
                            floatingLabel={true}
                            whiteSpace={false}
                            disabled={true}
                            onBlur={OtpInFormik.handleBlur}
                            value={OtpInFormik.values?.name}
                            onchangeCallback={OtpInFormik.handleChange}
                            inputClassName={OtpInFormik.touched.name && OtpInFormik.errors.name ? " is-invalid" : ""}
                            requiredMessage={OtpInFormik.touched.name && OtpInFormik.errors.name}
                            requiredMessageLabel={OtpInFormik.touched.name || OtpInFormik.isSubmitting ? OtpInFormik.errors.name : ""}
                        />
                    </div> */}
                    <div className="col-md-10">
                        <InputField
                            label={'Mobile number'}
                            placeholder={' '}
                            type="text"
                            className="mb-15 floating-label-input"
                            inputName={`mobile`}
                            maxLength={15}
                            floatingLabel={true}
                            whiteSpace={false}
                            disabled={true}
                            onBlur={OtpInFormik.handleBlur}
                            value={OtpInFormik.values?.mobile}
                            onchangeCallback={OtpInFormik.handleChange}
                            inputClassName={OtpInFormik.touched.mobile && OtpInFormik.errors.mobile ? " is-invalid" : ""}
                            requiredMessage={OtpInFormik.touched.mobile && OtpInFormik.errors.mobile}
                            requiredMessageLabel={OtpInFormik.touched.mobile || OtpInFormik.isSubmitting ? OtpInFormik.errors.mobile : ""}
                        />
                    </div>
                    <div className="col-2">
                        <CustomCheckBox
                            id={'mobile_status'}
                            name={'mobile_status'}
                            value={true}
                            checked={OtpInFormik.values.mobile_status}
                            className={'mb-15 mt-20 checkbox-field'}
                            onchangeCallback={OtpInFormik.handleChange}
                        // label={<p></p>}
                        />
                    </div>
                    {/* <div className="col-md-10">
                        <InputField
                            placeholder={' '}
                            label={'Email'}
                            type="email"
                            className="mb-15 floating-label-input"
                            inputName="email"
                            whiteSpace={false}
                            floatingLabel={true}
                            disabled={true}
                            maxLength={40}
                            onBlur={OtpInFormik.handleBlur}
                            value={OtpInFormik.values.email}
                            onchangeCallback={OtpInFormik.handleChange}
                            inputClassName={OtpInFormik.touched.email && OtpInFormik.errors.email ? " is-invalid" : ""}
                            requiredMessage={OtpInFormik.touched.email && OtpInFormik.errors.email}
                            requiredMessageLabel={OtpInFormik.touched.email || OtpInFormik.isSubmitting ? OtpInFormik.errors.email : ""}
                        />
                    </div> */}
                    {/* <div className="col-2">
                        <CustomCheckBox
                            id={'email_status'}
                            name={'email_status'}
                            value={true}
                            checked={OtpInFormik.values.email_status}
                            className={'mb-15 mt-20 checkbox-field'}
                            onchangeCallback={OtpInFormik.handleChange}
                        // label={<p>Email Status</p>}
                        />
                    </div> */}
                    <div className="col-md-12 d-flex justify-content-center">
                        <Button
                            btnText={'Update'}
                            onclickCallback={OtpInFormik.handleSubmit}
                            disabled={loading}
                            isLoading={loading}
                            btnClassName={'submit-btn'}
                            type="submit"
                        />
                    </div>
                </div>
            </div>
        </ModalBootstrap>
    )
}

export default OtpInUpdateModal