import React, { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from '../component/common/loader';
import Header from '../component/Header/Header';
import './scss/UserSideLayout.scss'
import Footer from '../component/Footer/Footer';

const DefaultLayout = (props) => {
    const location = useLocation();

    return (
        <Fragment>
            <div className="user-side-wrap">
                <Header />
                <Outlet />
                {
                    location.pathname === '/terms-condition' || location.pathname === '/opt-in' ?
                        null : <Footer />
                }
            </div>
            <Loader />
        </Fragment>
    )
}
export default DefaultLayout;