import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import './Home.scss';
import { pageTitle } from "../../helper/CommonFunctions";
import VideoUpload from '../../component/VideoUpload/VideoUpload';
import VideosCard from '../../component/VideosCard/VideosCard';
import MultipleChecked from '../../component/MultipleChecked/MultipleChecked';
import VideoShareModal from '../../component/Modals/VideoShareModal/VideoShareModal';
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';

import { Grid, Navigation, Pagination } from 'swiper/modules';
import { Link, useLocation } from 'react-router-dom';
import path from '../../routes/path';
import DoubleConfirmationModal from '../../component/DoubleConfirmationModal/DoubleConfirmationModal';
import VideoDetailsModal from '../../component/Modals/VideoDetailsModal/VideoDetailsModal';
import SwiperCore from 'swiper';
import AxiosServices from '../../networks/AxiosService';
import ApiUrlServices from '../../networks/ApiUrlServices';
import SelectonVideoSection from '../../component/Skeleton/SelectonVideoSection';
import { toast } from 'react-toastify';
import noVideoImg from '../../assets/image/no-videos.png'
import VideoUploadModal from '../../component/Modals/VideoUploadModal/VideoUploadModal';
import AddModuleModal from '../../component/Modals/AddModuleModal/AddModuleModal';
import { useDispatch, useSelector } from 'react-redux';
import { videoAction } from '../../store/videoSlice';
import CustomPagination from '../../component/paginations/CustomPagination';

SwiperCore.use([Grid, Pagination, Navigation]);


const Home = (props) => {
    pageTitle(props.pageTitle);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let pageSize = parseInt(params.get('page'));

    const [pageLoading, setPageLoading] = useState(true);
    const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'

    const [homePageVideo, setHomePageVideo] = useState([])
    const [selectedFilterItem, setSelectedFilterItem] = useState(null)

    const [noVideoFound, setNoVideoFound] = useState(false)

    const [videoDeleteConfirmation, seVideoDeleteConfirmation] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [videoDeleteId, setVideoDeleteId] = useState(null)

    const [videoShareModal, setVideoShareModal] = useState(false); // video share modal

    const [isChecked, setIsChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const [singleShareVideoId, setSingleShareVideoId] = useState([]); // single video share modal

    const [VideoMenuId, setVideoMenuId] = useState(null);
    const toggleOpenVideoBUttonRef = useRef(null)
    const toggleVideoMenuBoxRef = useRef();

    const [videoDetailsModal, setVideoDetailsModal] = useState(false);
    const [VideoDetailsData, setVideoDetailsData] = useState('');

    const [addModule, setAddModule] = useState(false);
    const [videoUploadModal, setVideoUploadModal] = useState(false);
    const [videoEditData, setVideoEditData] = useState({});

    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => setWidth(window.innerWidth);

    const videoUploadRender = useSelector((state) => state.videoReducer.videoUploadRender);

    const limit = 10;

    const [currentPage, setCurrentPage] = useState(pageSize);
    const [totalCount, setTotalCount] = useState(0);

    const handleAddModule = () => {
        setAddModule(!addModule)
    }

    const handleVideoUploadModal = (data) => {
        setVideoUploadModal(!videoUploadModal)
        setVideoEditData(data)
    }

    // this is called when someone gets back to this from another page
    useEffect(() => {
        setCurrentPage(pageSize)
    }, [pageSize]);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    useEffect(() => {
        if (selectedFilterItem) {
            getModuleByVideos(selectedFilterItem)
        } else {
            if (videoUploadRender){
                getAllVideos()
            }
        }
    }, [videoUploadRender])


    // anywhere click and hide profile menu Function
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                VideoMenuId &&
                toggleVideoMenuBoxRef.current &&
                !toggleVideoMenuBoxRef.current.contains(event.target) &&
                toggleOpenVideoBUttonRef.current &&
                !toggleOpenVideoBUttonRef.current.contains(event.target)
            ) {
                handleVideoOpen(VideoMenuId);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [VideoMenuId]);

    const handleVideoOpen = (id) => {
        setVideoMenuId(VideoMenuId === id ? null : id)
    }

    const getAllVideos = () => {
        setPageLoading(true);
        let payload = {
            module_limit: limit,
            module_page: isNaN(currentPage) ? 1 : Number(currentPage)
        }
        AxiosServices.get(ApiUrlServices.GET_ALL_ADMIN_Videos, payload)
            .then((res) => {
                console.log(res.data)
                const Modules = res.data.data.modules;
                const filteredModules = Modules.filter(module => module.videos.length > 0);
                setHomePageVideo(filteredModules)
                setTotalCount(res.data.data.total)
                const videoNotFound = Modules?.every(module => module.videos.length === 0);
                videoNotFound ? setNoVideoFound(true) : setNoVideoFound(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error('Failed to get videos')
            })
            .finally(() => {
                setPageLoading(false)
                dispatch(videoAction.setVideoUploadRender(false))
            })
    }

    const getModuleByVideos = (id) => {
        setPageLoading(true);
        AxiosServices.get(ApiUrlServices.GET_ALL_VIDEO_BY_MODULE(id))
            .then((res) => {
                console.log(res.data)
                setHomePageVideo([res.data.data.module])
                setTotalCount(1)
                res.data.data.module.videos.length === 0 ? setNoVideoFound(true) : setNoVideoFound(false)
            })
            .catch((err) => {
                console.log(err)
                const errorMessageCode = err.response.data.status_code
                if (errorMessageCode === 404) {
                    toast.error('Videos not found')
                } else {
                    toast.error('Failed to get videos')
                }
            })
            .finally(() => {
                setPageLoading(false)
                dispatch(videoAction.setVideoUploadRender(false))
            })
    }


    const multipleCheckedTopBar = () => {
        setSelectedItems([])
        setIsChecked(true)
        setSingleShareVideoId([])
    }

    const handleVideoShareModal = () => {
        setVideoShareModal(!videoShareModal)
    }

    const handleVideoDeleteConfirmation = (id) => {
        seVideoDeleteConfirmation(!videoDeleteConfirmation)
        setVideoDeleteId(id)
    }

    const handleVideoDelete = (id) => {
        console.log(id, 'delete')
        setDeleteLoading(true)
        AxiosServices.remove(ApiUrlServices.DELETE_ADMIN_Videos(id))
            .then((res) => {
                console.log(res.data)

                if (selectedFilterItem) {
                    getModuleByVideos(selectedFilterItem)
                } else {
                    getAllVideos()
                }

                setVideoDetailsData(null)
                setVideoDetailsModal(false)
                seVideoDeleteConfirmation(false)
            })
            .catch((err) => {
                console.log(err)
                const errorMessageCode = err.response.data.status_code
                if (errorMessageCode === 404) {
                    toast.error('Video not found')
                } else {
                    toast.error('Failed to delete video')
                }
            })
            .finally(() => {
                setDeleteLoading(false)
                toast.success('Video deleted successfully')
            })
    }

    const handleVideoDetailsModal = (data) => {
        setVideoDetailsData(data)
        setVideoDetailsModal(!videoDetailsModal)
    }

    const gridConfig = {
        list: {
            desktop: { slidesPerView: 2, grid: { rows: 2, fill: 'row' } },
            laptop: { slidesPerView: 2, grid: { rows: 2, fill: 'row' } },
            tablet: { slidesPerView: 1.7, grid: { rows: 1, fill: 'row' } },
            mobile: { slidesPerView: 1.5, grid: { rows: 1, fill: 'row' } },
            smallmMobile: { slidesPerView: 1.2, grid: { rows: 1, fill: 'row' } },
        },
        grid: {
            desktop: { slidesPerView: 4, grid: { rows: 1, fill: 'row' } },
            laptop: { slidesPerView: 3.5, grid: { rows: 1, fill: 'row' } },
            tablet: { slidesPerView: 2.8, grid: { rows: 1, fill: 'row' } },
            mobile: { slidesPerView: 1.9, grid: { rows: 1, fill: 'row' } },
            smallmMobile: { slidesPerView: 1.4, grid: { rows: 1, fill: 'row' } },
        }
    };

    const getGridConfig = () => {
        if (width >= 1200) return gridConfig[viewMode].desktop;
        if (width >= 991) return gridConfig[viewMode].laptop;
        if (width >= 768) return gridConfig[viewMode].tablet;
        if (width >= 576) return gridConfig[viewMode].mobile;
        return gridConfig[viewMode].smallmMobile;
    };



    return (
        <>
            <div className={`home-page ${selectedItems.length > 0 ? 'active' : ''}`}>
                <MultipleChecked
                    multipleCheckedTopBar={multipleCheckedTopBar}
                    selectedItems={selectedItems}
                    handleVideoShareModal={handleVideoShareModal}
                />
                <VideoUpload
                    getAllVideos={getAllVideos}
                    getModuleByVideos={getModuleByVideos}
                    setViewMode={setViewMode}
                    viewMode={viewMode}
                    selectedItems={selectedItems}
                    setSelectedFilterItem={setSelectedFilterItem}
                    handleAddModule={handleAddModule}
                    handleVideoUploadModal={handleVideoUploadModal}
                />
                <div className="video-section-wrap">
                    {
                        pageLoading &&
                        <SelectonVideoSection quantity={2} />
                    }
                    <div className={pageLoading && 'd-none'}>
                        <CustomPagination
                            onPageChange={getAllVideos}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={Math.ceil(Number(totalCount) / limit)}
                        >
                            {
                                homePageVideo?.map((module, i) => (
                                    <div
                                        key={i}
                                        style={{
                                            borderBottom: i !== homePageVideo.length - 1 ? '1px solid #0000001A;' : 'none',
                                        }}
                                        className={`video-section ${module.videos.length === 0 && 'd-none'} ${viewMode === 'list' ? 'video-list-section pb-30' : ''}`}>
                                        <div className='video-section-top'>
                                            <div className='video-section-top-left'>
                                                <h2>{module.name}</h2>
                                            </div>
                                            {
                                                module.videos.length > 4 && (
                                                    <div className="video-section-top-right">
                                                        <Link className={`btn-outline ${selectedItems?.length > 0 ? 'disable' : ''}`}
                                                            to={`${path.view_all}?page=1&module_id=${module.id}`}>View all</Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <Swiper
                                            navigation
                                            {...getGridConfig()}
                                            spaceBetween={20}
                                            className={`video-slider ${module?.videos?.length <= 4 ? viewMode === 'list' ? 'list-slider' : '' : ''}`}
                                        >
                                            {
                                                module.videos.map((data, index) => (
                                                    <SwiperSlide key={index}>
                                                        <VideosCard
                                                            data={data}
                                                            id={data.id}
                                                            width={width}
                                                            adminCard={true}
                                                            viewMode={viewMode}
                                                            isChecked={isChecked}
                                                            handleVideoDetailsModal={handleVideoDetailsModal}
                                                            handleVideoDeleteConfirmation={handleVideoDeleteConfirmation}
                                                            setIsChecked={setIsChecked}
                                                            selectedItems={selectedItems}
                                                            setSelectedItems={setSelectedItems}
                                                            VideoMenuId={VideoMenuId}
                                                            handleVideoOpen={handleVideoOpen}
                                                            handleVideoUploadModal={handleVideoUploadModal}
                                                            toggleVideoMenuBoxRef={toggleVideoMenuBoxRef}
                                                            toggleOpenVideoBUttonRef={toggleOpenVideoBUttonRef}
                                                        />
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                ))
                            }
                        </CustomPagination>
                    </div>
                    {
                        !pageLoading && noVideoFound &&
                        <div className='no-video-found'>
                            <img src={noVideoImg} alt="" />
                            <span>No Videos</span>
                        </div>
                    }
                </div>
            </div>
            {
                videoShareModal && (
                    <VideoShareModal
                        singleShareVideoId={singleShareVideoId}
                        multipleCheckedTopBar={multipleCheckedTopBar}
                        selectedItems={selectedItems}
                        handleModalOpenClose={handleVideoShareModal}
                    />
                )
            }

            {
                videoDeleteConfirmation ?
                    <DoubleConfirmationModal
                        open={videoDeleteConfirmation}
                        close={seVideoDeleteConfirmation}
                        loading={deleteLoading}
                        submit={() => handleVideoDelete(videoDeleteId)}
                        disabled={deleteLoading}
                        className="btn-danger"
                        title={'Are you sure to delete this video?'}
                        text={'You cannot undo this action.'}
                    />
                    : null
            }

            {
                videoUploadModal || videoDeleteConfirmation || videoShareModal ? false : videoDetailsModal ?
                    <VideoDetailsModal
                        VideoDetailsData={VideoDetailsData}
                        setSelectedItems={setSelectedItems}
                        setSingleShareVideoId={setSingleShareVideoId}
                        handleVideoShareModal={handleVideoShareModal}
                        handleModalOpenClose={handleVideoDetailsModal}
                        handleVideoUploadModal={handleVideoUploadModal}
                        handleVideoDeleteConfirmation={handleVideoDeleteConfirmation}
                    /> : null
            }

            {
                addModule ?
                    <AddModuleModal
                        handleModalOpenClose={handleAddModule} /> : null
            }
            {
                addModule ? false : videoUploadModal ?
                    <VideoUploadModal
                        videoEditData={videoEditData}
                        setVideoDetailsData={setVideoDetailsData}
                        handleModalOpenClose={handleVideoUploadModal}
                        handleAddModule={handleAddModule} /> : null
            }

        </>
    )
}
export default Home;