import React, {useEffect} from "react";
import "./cropper.css";
import Cropper from "react-easy-crop";
import {getCroppedImg} from "./utils/cropImage";
import {toast} from 'react-toastify';
import {STATIC_MSG} from "./utils/config"
import Commonbutton from "../button/Button";


const ImgCropper = (props) => {
    const [image, setImage] = React.useState(props.imageFile);
    const [imageName, setImageName] = React.useState("");
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({x: 0, y: 0});
    const [zoom, setZoom] = React.useState(1);
    const cropperAspect = props.aspectRatio;
    const cropperResulation = props.resulation;
    const cropShape = props.cropShape;
    const aspectRatioSize = props.aspectRatioSize;
    const cropImgSize = props.cropImgSize;
    const wrapperClassName = props.wrapperClassName + ' ' + 'cropper-main-container';

    useEffect(() => {
        let name = props.imageName;
        let list = name.split(".");
        const ext = list.pop();

        name = list.join(".") + String(Date.now()) + `.${ext}`;
        setImageName(name);
    }, []);

    const onChangeFile = () => {
        props.inputRef.current.value = "";
        props.inputRef?.current?.click();
        props.onClose(false);
    }

    const onCancel = () => {
        props.inputRef.current.value = "";
        props.onClose(false);
    }

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };


    const onRequired = (image) => {
        if (!image) {
            toast.warn(STATIC_MSG.UPLOAD_REQUIRED_MSG, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
    };

    const onUpload = async () => {
        onRequired(image);
        let canvas = await getCroppedImg(image, croppedArea, cropImgSize, imageName.split(".").pop());
        canvas.name = imageName;

        props.callback(canvas);
        props.onClose(false);

    };

    return (
        <>
            <div className={props.show ? "cropper-image-overly active" : "cropper-image-overly"} />
            <div className={wrapperClassName}>
            <div className='container-cropper'>
                {image ? (
                    <>
                        <div className='cropper'>
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={cropperAspect}
                                cropShape={cropShape}
                                cropSize={aspectRatioSize}
                                showGrid={false}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>

                        <div className='slider'>
                            <div className="controls">
                                <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e) => {
                                        setZoom(e.target.value)
                                    }}
                                    className="zoom-range"
                                />
                            </div>
                        </div>
                    </>
                ) : null}

                <div className='action-block w-100'>
                <Commonbutton
                    type="button"
                    onclickCallback={onCancel}
                    btnClassName="form-cancel"
                    btnText="Cancel"
                />
                <Commonbutton
                    type="button"
                    onclickCallback={onChangeFile}
                    btnClassName="form-submit choose"
                    btnText="Choose"
                />
                <Commonbutton
                    type="button"
                    onclickCallback={onUpload}
                    btnClassName="form-submit"
                    btnText="Upload"
                />
            </div>
            </div>
        </div>
        </>
    );
}

export default ImgCropper;
