import React from 'react'
import './Sidebar.scss';
import SidebarButton from './sidebarButton';
import { useTranslation } from "react-i18next";
import path from '../../routes/path';
import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate()



    const menuItem = [
        {
            "name": `${t('sidebar.home')}`,
            "icon": "ic:round-home",
            "path": `${process.env.PUBLIC_URL}${path.dashboard}`,
            "submenu": false
        },
        // {
        //     "name": `${t('sidebar.campaign')}`,
        //     "icon": "gg:image",
        //     "path": `${process.env.PUBLIC_URL}${path.campaign}`,
        //     "submenu": false,
        // },
        // {
        //     "name": `${t('sidebar.payment_gateway')}`,
        //     "icon": "fluent:payment-32-regular",
        //     "path": `${process.env.PUBLIC_URL}${path.payment_gateway}`,
        //     "submenu": false
        // },
        // // {
        // //     "name": `${t('sidebar.users_management')}`,
        // //     "icon": "heroicons:user-group",
        // //     "path": `${process.env.PUBLIC_URL}${path.user_management}`,
        // //     "submenu": false
        // // },
        // {
        //     "name": `${t('sidebar.my_account')}`,
        //     "icon": "solar:user-outline",
        //     "path": `${process.env.PUBLIC_URL}${path.my_account}`,
        //     "submenu": false,
        // },
        // {
        //     "name": `${t('sidebar.donor_list')}`,
        //     "icon": "solar:users-group-rounded-outline",
        //     "path": `${process.env.PUBLIC_URL}${path.donor_list}`,
        //     "submenu": false
        // },
        // {
        //     "name": "Reports",
        //     "icon": "heroicons:chart-bar-square",
        //     "path": `${process.env.PUBLIC_URL}${path.donation_report}`,
        //     "submenu": true,
        //     "submenu_item": [
        //         {
        //             "name": `${t('sidebar.donation_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.donation_report}`
        //         },
        //         {
        //             "name": `${t('sidebar.pledges_receivable_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.pledges_receivable_report}`
        //         },
        //         {
        //             "name": `${t('sidebar.donor_limit_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.donor_limit_report}`,
        //         },
        //         {
        //             "name": `${t('sidebar.donor_history_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.donor_history_report}`,
        //         },
        //         {
        //             "name": `${t('sidebar.Match_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.match_report}`,
        //         },
        //         {
        //             "name": `${t('sidebar.expense_report')}`,
        //             "path": `${process.env.PUBLIC_URL}${path.expense_report}`,
        //         },
        //         // {
        //         //   "name": `${t('sidebar.due_report')}`,
        //         //   "path": `${process.env.PUBLIC_URL}${path.due_report}`,
        //         // },
        //         // {
        //         //   "name": `${t('sidebar.final_report')}`,
        //         //   "path": `${process.env.PUBLIC_URL}${path.final_report}`,
        //         // }
        //     ]
        // },
        // {
        //     "name": `${t('sidebar.bulk_history')}`,
        //     "icon": "icon-park-outline:upload-logs",
        //     "path": `${process.env.PUBLIC_URL}${path.bulk_history}`,
        //     "submenu": false
        // },
        // {
        //     "name": `${t('sidebar.follow_up')}`,
        //     "icon": "uiw:date",
        //     "path": `${process.env.PUBLIC_URL}${path.follow_up_call}`,
        //     "submenu": false
        // },
        // {
        //     "name": `${t('sidebar.purchase_history')}`,
        //     "icon": "material-symbols:work-history-outline",
        //     "path": `${process.env.PUBLIC_URL}${path.purchase_history}`,
        //     "submenu": false
        // }
    ]

    const clickMenu = (path) => {
        navigate(path);
    }
    return (
        <>
            <div onClick={props.handleSideBar} className={`sidebar-overlay ${props.sideBar ? 'active' : ''}`}></div>
            <div className={`sidebar ${props.sideBar ? 'active' : ''}`}>
                <div className='single-sidebar-menu'>
                    <nav>
                        <ul>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {menuItem.map((item, i) =>
                                    <li key={i}>
                                        {!item.submenu ?
                                            <SidebarButton to={item.path} buttonText={item.name} icon={item.icon} />
                                            :
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" data-id={i}>
                                                    <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${i}`} aria-expanded="false" aria-controls={`flush-collapse${i}`}>
                                                        <span className="service" ><Icon width={20} height={20} icon={item.icon} />{item.name}</span>
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                    <ul>
                                                        {item.submenu_item.map((data, i) =>
                                                            <li key={i}>
                                                                <SidebarButton to={data.path} buttonText={data.name} />
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </li>
                                )}
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Sidebar
