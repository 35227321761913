export const STATIC_CONST = {
    //IMG_UPLOAD_API: "brand-images/",
    IMG_OUTPUT_QUALITY: 0.90,
}
export const STATIC_MSG = {
    UPLOAD_SUCCESS_MSG: "Image Succesfully Uploaded",
    UPLOAD_ERROR_MSG: "Image Uploaded ERROR",
    UPLOAD_REQUIRED_MSG: "Please select an image!",
}

export default STATIC_CONST
