import React, { useState } from 'react'
import './FeedbackModal.scss'
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import StarRating from './StarRating'
import TextareaSimple from '../../textareaSimple/textareaSimple'
import { useFormik } from 'formik'
import Button from '../../button/Button'
import AxiosServices from "../../../networks/AxiosService";
import ApiUrlServices from "../../../networks/ApiUrlServices";
import {toast} from "react-toastify";

const FeedbackModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(1);

    const validationVideoFeedbackForm = (values) => {
        const errors = {}
        if (!values.description.trim() || values.description.trim().length < 10) {
            errors.description = "Please write minimum 10 characters"
        }
        console.log(errors)
        return errors
    }

    const submitVideoFeedbackForm = (values) => {
        setLoading(true);
        let payload = {
            rating: Number(rating),
            feedback: values.description,
            video_id: Number(props.video_id)
        }
        AxiosServices.post(ApiUrlServices.CREATE_VIDEOS_FEEDBACK(props.video_id), payload)
            .then((res) => {
                console.log(res.data)
                toast.success('Thanks for your feedback.')
            })
            .catch((err) => {
                console.log(err)
                toast.error('Something went wrong! Please try later')
            })
            .finally(() => {
                setLoading(false)
                props.handleModalOpenClose()
            })
    }

    const VideoFeedbackFormFormik = useFormik({
        initialValues: {
          rating: 0,
          description: "",
          video_id : ""
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: validationVideoFeedbackForm,
        onSubmit: submitVideoFeedbackForm
    })

    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            size={"md"}
            class='feedback-modal'
        >
            <div className="modal-body">
                <div className="feedback-modal-top">
                    <h3>Rate your experience</h3>
                    <p>We highly value your feedback! Kindly take a moment to rate your experience and provide us with your valuable feedback.</p>
                </div>
                <StarRating rating={rating} setRating={setRating} totalStars={5} />
                <TextareaSimple
                    id="description"
                    className="mb-15 floating-label-input"
                    onBlur={VideoFeedbackFormFormik.handleBlur}
                    name="description"
                    placeholder="Tell us more about your experience!"
                    asterisk={false}
                    // floatingLabel={true}
                    // textCount={true}
                    maxLength={200}
                    value={VideoFeedbackFormFormik.values.description}
                    onchangeCallback={VideoFeedbackFormFormik.handleChange}
                    inputClassName={VideoFeedbackFormFormik.touched.description && VideoFeedbackFormFormik.errors.description ? " is-invalid" : ""}
                    requiredMessage={
                        VideoFeedbackFormFormik.touched.description &&
                        VideoFeedbackFormFormik.errors.description
                    }
                    requiredMessageLabel={
                        VideoFeedbackFormFormik.touched.description ||
                            VideoFeedbackFormFormik.isSubmitting
                            ? VideoFeedbackFormFormik.errors.description
                            : ""
                    }
                />
                 <div className='upload-video-btn'>
                            <Button
                                btnText={'Cancel'}
                                onclickCallback={props.handleModalOpenClose}
                                disabled={loading}
                                type="button"
                                btnClassName="cancel-btn"
                            />
                            <Button
                                btnText={'Submit'}
                                onclickCallback={VideoFeedbackFormFormik.handleSubmit}
                                disabled={loading}
                                isLoading={loading}
                                btnClassName="submit-btn"
                                type="submit"
                            />
                        </div>
            </div>
        </ModalBootstrap>
    )
}

export default FeedbackModal