import React, { useState } from "react";
import './Footer.scss'
import Button from "../button/Button";
import OtpInUpdateModal from "../Modals/OtpInUpdateModal/OtpInUpdateModal";

const Footer = () => {
    const [otpInUpdateModal, setOtpInUpdateModal] = useState(false);

    const handleOtpInUpdateModalOpenClose = () => {
        setOtpInUpdateModal(!otpInUpdateModal)
    }

    return (
        <>
            <footer className="footer">
                <Button
                    btnText={'Update Settings'}
                    onclickCallback={handleOtpInUpdateModalOpenClose}
                    btnClassName={'submit-btn'}
                    type="submit"
                />
            </footer>
            {
                otpInUpdateModal &&
                <OtpInUpdateModal
                    handleModalOpenClose={handleOtpInUpdateModalOpenClose}
                />
            }
        </>
    )
}
export default Footer;