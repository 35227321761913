// create the image with a src of the base64 string
import {STATIC_CONST} from "./config";

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

export const getCroppedImg = async (imageSrc, crop, cropImgSize, imageType) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    /* setting canvas width & height allows us to
    resize from the original image resolution */
    if (cropImgSize){
        console.log('cropImgSize custom', cropImgSize)
        canvas.width = cropImgSize.width
        canvas.height = cropImgSize.height
    }else{
        console.log('cropImgSize : Auto')
        canvas.width = crop.width
        canvas.height = crop.height
    }
    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob)
        }, imageType)
    })
}

export const generateDownload = async (imageSrc, crop) => {
    if (!crop || !imageSrc) {
        return;
    }
    const canvas = await getCroppedImg(imageSrc, crop);
    canvas.toBlob(
        (blob) => {
            const previewUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.download = "image.jpeg";
            anchor.href = URL.createObjectURL(blob);
            anchor.click();
            window.URL.revokeObjectURL(previewUrl);
        },
        "image/jpeg",
        STATIC_CONST.IMG_OUTPUT_QUALITY
    );
};