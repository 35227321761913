import React, { useEffect, useState } from 'react'
import './VideosCard.scss'
import { Icon } from '@iconify/react/dist/iconify.js'
import { bucket_base_url, cloudfront_base_url } from '../../config/config'
import { useNavigate } from 'react-router-dom'
import path from '../../routes/path'
import { handleDownloadVideo } from '../../helper/CommonFunctions'
import ConversationStatusModal from '../Modals/ConversationStatusModal/ConversationStatusModal'

const VideosCard = (props) => {
    const navigate = useNavigate()
    const [conversationStatusModal, setConversationStatusModal] = useState(false);
    useEffect(() => {
        const checkbox = document.getElementById(`checkbox[${props.id}]`)
        if (props.isChecked) {
            if (checkbox.checked) {
                console.log('data')
                checkbox.checked = false;
            }
            props.setIsChecked(false)
        }

        if (props?.selectedItems?.length > 0) {
            checkbox.checked = props?.selectedItems?.includes(props.id) ? true : false;
        }
    }, [props.isChecked, props?.selectedItems?.includes(props.id)])



    const handleChangeCheckbox = (e) => {
        const { value, checked } = e.target;
        let updatedItems = [...props?.selectedItems];
        if (checked) {
            updatedItems.push(Number(value)); // Add the checked value to the array
        } else {
            updatedItems = updatedItems.filter((item) => item !== Number(value)); // Remove the unchecked value from the array
        }

        props.setSelectedItems(updatedItems);
    };

    const handleShowConversationStatusModal = () => {
        console.log('clicked')
        if (props?.data?.conversion_status === 'Pending' ||
            props?.data?.conversion_status === 'Processing' ||
            props?.data?.conversion_status === 'Failed') {
            setConversationStatusModal(!conversationStatusModal)
        }
    }

    const handleGotVideoShow = () => {
        navigate(`${path.client_video_show}?module_id=${props.module_id}&video_id=${props.id}&shared_id=${props.shared_id}`)
    }
    // console.log(props.data.conversion_status)

    return (
        <>
            <div
                onClick={() =>
                    handleShowConversationStatusModal()
                }
                className={
                    `video-card 
            ${props?.data?.conversion_status === 'Pending' ||
                        props?.data?.conversion_status === 'Processing' ||
                        props?.data?.conversion_status === 'Failed'
                        ? 'pending-video-card' : ''} 
            ${props?.viewMode === 'list' ? 'list-view-video-card' : ''}`
                }
            >
                {
                    props.adminCard &&
                    <>
                        <div className={`checkbox ${props?.selectedItems?.length > 0 && 'active'}`}>
                            <div className="round">
                                <input
                                    value={props?.id}
                                    onChange={handleChangeCheckbox}
                                    type="checkbox"
                                    name={`checkbox[${props.id}]`}
                                    id={`checkbox[${props.id}]`}
                                />
                                <label htmlFor={`checkbox[${props.id}]`}></label>
                            </div>
                        </div>
                    </>
                }

                <div
                    ref={props?.toggleVideoMenuBoxRef}
                    className={`three-dot-icon ${props?.VideoMenuId === props?.id && 'active'} ${props?.selectedItems?.length > 0 ? 'd-none' : ''}`}>
                    <button ref={props?.toggleOpenVideoBUttonRef} onClick={() => props?.handleVideoOpen(props?.id)} className="three-dot-button"
                    >
                        <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div className={`dropdown-menus ${props?.VideoMenuId === props?.id && 'active'}`}>
                        <ul className='menu-ul'>
                            {props.adminCard && <li className='menu'><button onClick={() => props.handleVideoUploadModal({ edit: true, data: props?.data })} ><Icon icon={'ic:sharp-edit'} />Edit</button></li>}
                            {props.adminCard && <li className='menu'><button onClick={() => props.handleVideoDeleteConfirmation(props.id)}><Icon icon={'mingcute:delete-fill'} />Delete</button></li>}
                            <li className='menu'><button onClick={() => handleDownloadVideo(props?.data?.video_file_path)} ><Icon icon={'basil:download-solid'} />Download</button></li>
                        </ul>
                    </div>
                </div>

                <div
                    onClick={() =>
                        props?.adminCard ?
                            props.handleVideoDetailsModal(props.data) :
                            handleGotVideoShow(props.id)
                    }
                    className={`video-card-thumbnail ${props?.selectedItems?.length > 0 ? 'checked-video-opacity' : ''} ${props?.selectedItems?.includes(props.id) ? 'checked-video' : ''}`}>
                    {/* props.selectedItems.includes(props.id) */}
                    <div className="card-inner">
                        <img src={bucket_base_url + props.data.thumbnail_path} alt="" />
                        {
                            props.data.duration !== "" ? <span>{props.data.duration}</span> : ""
                        }
                        {props?.data?.conversion_status === 'Pending' ||
                            props?.data?.conversion_status === 'Processing' ||
                            props?.data?.conversion_status === 'Failed'
                            ? <span className='conversation-status'>{props?.data?.conversion_status}</span> : ''}
                    </div>
                </div>
                <div className='video-card-header'>
                    <h2 className={`${props?.viewMode === 'list' ? 'one-line-clamp' : 'two-line-clamp'}`} onClick={() =>
                        props?.adminCard ?
                            props.handleVideoDetailsModal(props.data) :
                            handleGotVideoShow(props.id)
                    }>
                        {props?.data?.title}
                    </h2>

                    <p className={`two-line-clamp`}>
                        {props?.data?.description}</p>
                </div>
            </div>
            {
                conversationStatusModal &&
                <ConversationStatusModal
                    handleModalOpenClose={handleShowConversationStatusModal}
                    data={props.data}
                    handleVideoEditModal={props.handleVideoUploadModal}
                />
            }
        </>
    )
}

export default VideosCard