import React from 'react'
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import './ConversationStatusModal.scss'

const ConversationStatusModal = (props) => {
    const handleVideoTryAgainModal = () => {
        props.handleModalOpenClose()
        props.handleVideoEditModal({ edit: true, data: props?.data })
    }
    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            title='Conversion Status'
            size={"md"}
            class='conversion-modal'
        >
            <div className="modal-body">
                {props?.data?.conversion_status === 'Pending' ||
                    props?.data?.conversion_status === 'Processing' ?
                    <p>Video conversion is in progress. Please hold on while the operation completes. Once finished, you will be able to play the video. Thank you for your patience!</p>
                    : ''
                }
                {
                    props?.data?.conversion_status === 'Failed' ?
                        <p>The conversion operation was interrupted. Please re-upload the video and <span onClick={() =>
                            handleVideoTryAgainModal()
                        }>try again.</span></p>
                        : ''
                }

            </div>
        </ModalBootstrap>
    )
}

export default ConversationStatusModal