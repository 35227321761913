import React, {useState, useEffect, Fragment} from 'react';

const ProgressBar = ({progress}) => {
    const containerStyles = {
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 4,
    };

    const fillerStyles = {
        height: '6px',
        width: `${progress}%`,
        backgroundColor: '#007bff',
        borderRadius: 'inherit',
        textAlign: 'center'
    };

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: '300',
        fontSize: '12px'
    };

    return (
        <Fragment>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}></span>
                </div>
            </div>
        </Fragment>
    );
};

export default ProgressBar;
