import React from "react";
import './Button.scss'
import { Icon } from "@iconify/react";

const Button = (props) => {
    return (
        <button
            type={props.type !== undefined ? props.type : 'submit'}
            title={props.title}
            ref={props.inputRef}
            onClick={props.onclickCallback}
            className={`button-1 ${props.btnClassName}`}
            disabled={props.isLoading || props.disabled}
        >
            {
                props.isLoading && (<span className="loading-icon"><Icon width={'18px'} icon={'eos-icons:loading'} /></span>)
            }
            {
                props.icon1 && (<span className="icon-1"><Icon icon={props.icon1} /></span>)
            }
            {props.btnText && (<span className="btn-text">{props.btnText}</span>)}
            {
                props.icon2 && (<span className="icon-2"><Icon icon={props.icon2} /></span>)
            }
        </button>
    );
}
export default Button;
