import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import './scss/AuthLayout.scss'

const AuthLayout = (props) => {
    return (
        <Fragment>
            <div className="auth-layout-wrapper">
                <Outlet />
            </div>
        </Fragment>
    );
}

export default AuthLayout;