import React from 'react';
import Style from './CustomLoader.module.scss';

export default function CustomLoader(props) {

    return (
        props.Loader ? <>
            <div className={`${Style.overlayed} ${props.overlayClassName}`} />
            <div className={`${Style.loader} ${props.loaderClassName}`}>
                <div className={`wave ${Style.wave}`}>
                    <span className={`dot ${Style.dot}`}></span>
                    <span className={`dot ${Style.dot}`}></span>
                    <span className={`dot ${Style.dot}`}></span>
                </div>
            </div>
        </> : props.default ? <div className={`${Style.defaultDesign}`}><span className="rotate dotted" /></div> : ""
    );
}
