import React, {useEffect, useRef, useState} from 'react'
import './VideoDetailsModal.scss'
import ModalBootstrap from '../../modalBootstrap/ModalBootstrap'
import {Icon} from '@iconify/react/dist/iconify.js'
import HLSPlayer from '../../videoComponent/HLSPlayer'
import {bucket_base_url, cloudfront_base_url} from '../../../config/config'
import {handleDownloadVideo, modifyURL} from '../../../helper/CommonFunctions'

const VideoDetailsModal = (props) => {

    const toggleOpenVideoBUttonRef = useRef(null)
    const toggleVideoMenuBoxRef = useRef();
    const [VideoDetailsMenuId, setVideoDetailsMenuId] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [videoPath, setVideoPath] = useState('');

    // anywhere click and hide profile menu Function
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                VideoDetailsMenuId &&
                toggleVideoMenuBoxRef.current &&
                !toggleVideoMenuBoxRef.current.contains(event.target) &&
                toggleOpenVideoBUttonRef.current &&
                !toggleOpenVideoBUttonRef.current.contains(event.target)
            ) {

                handleVideoOpen(VideoDetailsMenuId);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [VideoDetailsMenuId]);

    useEffect(()=>{
        let url = cloudfront_base_url + props?.VideoDetailsData?.video_file_path;
        console.log(modifyURL(url))
        setVideoPath(modifyURL(url))
    },[props?.VideoDetailsData?.video_file_path])

    const handleVideoOpen = (id) => {
        setVideoDetailsMenuId(VideoDetailsMenuId ? null : id)
    }


    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <ModalBootstrap
            show={props.handleModalOpenClose}
            handleClose={props.handleModalOpenClose}
            size={"lg"}
            class='video-details-modal'
        >
            <div className="modal-body">
                <div className="video-details-modal-heading-top">
                    <div className="video-details-modal-heading-top-right">
                        <div
                            ref={toggleVideoMenuBoxRef}
                            className={`three-dot-icon ${VideoDetailsMenuId && 'active'}`}>
                            <button ref={toggleOpenVideoBUttonRef} onClick={() => handleVideoOpen(props?.VideoDetailsData?.id)}
                                className="three-dot-button"
                            >
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <div className={`dropdown-menus ${VideoDetailsMenuId && 'active'}`}>
                                <ul className='menu-ul'>
                                    <li className='menu'><button onClick={() => {
                                        props.handleVideoShareModal()
                                        props.setSingleShareVideoId([props?.VideoDetailsData?.id])
                                    }} ><Icon icon={'ph:share-fat-fill'} />Share</button></li>
                                    <li className='menu'><button onClick={() => {
                                        props.handleVideoUploadModal({ edit: true, data: props?.VideoDetailsData })
                                    }}><Icon icon={'ic:sharp-edit'} />Edit</button></li>
                                    <li className='menu'>
                                        <button onClick={() => {
                                            props.handleVideoDeleteConfirmation(props?.VideoDetailsData?.id)
                                        }}>
                                            <Icon icon={'mingcute:delete-fill'} />Delete
                                        </button>
                                    </li>
                                    <li className='menu'><button onClick={() => handleDownloadVideo(props?.VideoDetailsData?.video_file_path)} ><Icon icon={'basil:download-solid'} />Download</button></li>
                                </ul>
                            </div>
                        </div>
                        <div className='close-icon' onClick={() => props.handleModalOpenClose()}>
                            <Icon icon={'akar-icons:cross'} />
                        </div>
                    </div>
                </div>
                <div className="video-details-modal-main">
                    <div className="video-details-modal-content-left-video-section">
                        <HLSPlayer thumbnail_img={bucket_base_url + props?.VideoDetailsData?.thumbnail_path} src={videoPath} />
                        {/* props.VideoDetailsData.video_file_path */}
                    </div>
                    <div className="video-details-modal-content">
                    <h3 title={props?.VideoDetailsData?.title} className='video-details-title'>{props?.VideoDetailsData?.title}</h3>
                        <p>
                            {props?.VideoDetailsData?.description.slice(0, props?.VideoDetailsData?.description?.length > 150 ? isExpanded ? props?.VideoDetailsData?.description?.length : 150 : props?.VideoDetailsData?.description?.length)}{props?.VideoDetailsData?.description?.length > 150 ? isExpanded ? '. ' : '... ' : '.'}
                            <span onClick={toggleText}>
                                {props.VideoDetailsData?.description?.length > 150 ? isExpanded ? 'less' : 'more' : ''}
                            </span >
                        </p>
                    </div>
                </div>
            </div>
        </ModalBootstrap >
    )
}

export default VideoDetailsModal