import React, { useEffect, useState } from 'react';
import './VideoUpload.scss'
import ReactSelectField from '../ReactSelectField/ReactSelectField';
import { useFormik } from 'formik';
import Button from '../button/Button';
import AddModuleModal from '../Modals/AddModuleModal/AddModuleModal';
import VideoUploadModal from '../Modals/VideoUploadModal/VideoUploadModal';
import AxiosServices from '../../networks/AxiosService';
import ApiUrlServices from '../../networks/ApiUrlServices';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { videoAction } from '../../store/videoSlice';


const VideoUpload = (props) => {
    const dispatch = useDispatch();
    const [getModuleOptionLoader, setGetModuleOptionLoader] = useState(false)
    const [moduleList, setModuleList] = useState([]);
    const moduleAddRender = useSelector((state) => state.videoReducer.moduleAddRender);

    const initialPayload = {
        page: 1,
        limit: 1000
    }

    useEffect(() => {
        getAllModules()
    }, [moduleAddRender])

    const getAllModules = () => {
        setGetModuleOptionLoader(true)
        AxiosServices.get(ApiUrlServices.CREATE_GET_MODULE, initialPayload)
            .then((res) => {
                const modules = res.data.data.modules;
                const module_list = modules.map((module) => {
                    return {
                        label: module.name,
                        value: module.id
                    }
                })
                setModuleList(module_list)
            })
            .catch((err) => {
                console.log(err)
                setGetModuleOptionLoader(false)
                toast.error('Failed to get module list')
            })
            .finally(() => {
                setGetModuleOptionLoader(false)
                dispatch(videoAction.setModuleAddRender(false))
            })
    }

    const VideoSearching = useFormik({
        initialValues: {
            video_searching: ''
        }
    })

    return (
        <>
            <div className={`video-upload-control ${props.selectedItems.length > 0 ? 'active' : ''}`}>
                <div className="video-upload-left">
                    <ReactSelectField
                        id="video_searching"
                        className="search-option floating-label-input"
                        name="video_searching"
                        placeholder='All'
                        asterisk={true}
                        whiteSpace={false}
                        options={moduleList}
                        loading={getModuleOptionLoader}
                        isClearable={true}
                        onBlur={(e) => {
                            VideoSearching.handleBlur(e)
                        }}
                        value={VideoSearching.values.video_searching}
                        noOptionsMessage={() => "No module available."}
                        onchangeCallback={(e) => {
                            VideoSearching.setFieldValue('video_searching', e)
                            if (e?.value) {
                                props.getModuleByVideos(e.value)
                                props.setSelectedFilterItem(e.value)
                            } else {
                                props.getAllVideos()
                                props.setSelectedFilterItem(null)
                            }
                        }}
                    />
                    <div className={`upload-buttons ${props.selectedItems.length > 0 ? 'active' : ''}`}>
                        <Button
                            icon1={'ion:add-outline'}
                            btnText={'Add Module'}
                            onclickCallback={props.handleAddModule}
                            btnClassName={'btn-outline'}
                        />
                        <Button
                            icon1={'ri:video-add-fill'}
                            btnText={'Upload Video'}
                            onclickCallback={() => props.handleVideoUploadModal({edit: false, data: {}})}
                            btnClassName={'btn-upload'}
                        />
                    </div>
                </div>
                <div className={`card-format ${props.selectedItems.length > 0 ? 'active' : ''}`}>
                    <Button
                        btnClassName={`${props.viewMode === 'grid' ? 'active-btn' : ''}`}
                        icon1={'clarity:grid-view-solid'}
                        onclickCallback={() => props.setViewMode('grid')}
                    />
                    <Button
                        btnClassName={`filter-btn ${props.viewMode === 'list' ? 'active-btn' : ''}`}
                        icon1={'zondicons:list'}
                        onclickCallback={() => props.setViewMode('list')}
                    />
                </div>
            </div>
           
        </>
    )
}

export default VideoUpload