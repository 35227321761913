import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from 'react-router-dom';
import {usePagination} from './logic';
import './styles.scss';

const CustomPagination = (props) => {
    const {t} = useTranslation();
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = {
        onPageChange: props.onPageChange,
        totalCount: props.totalPage,
        currentPage: props.currentPage,
        pageSize: 1,
        className: "pagination-bar"
    };

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount: 1,
        pageSize
    });
    const navigate = useNavigate()

    const {search, pathname} = useLocation();

    const [page, setPage] = useState({})

    const DOTS = "DOTS";

    const onNext = () => {

        if (!props.dynamo) {
            setPage(currentPage + 1)
            setQueryParams(`?page=${currentPage + 1}`)
        } else {
            dynamoQuerySetter(currentPage + 1)
        }
        props.setCurrentPage(currentPage + 1)
    };
    const onPrevious = () => {

        if (!props.dynamo) {
            setPage(currentPage - 1)
            setQueryParams(`?page=${currentPage - 1}`)
        } else {
            dynamoQuerySetter(currentPage - 1)
        }
        props.setCurrentPage(currentPage - 1)


    };
    const keys = ['page', 'pid', 'lepk', "lesk", "legsipk", "legsisk"]

    let setQueryParams = (queryString) => {
        const url = new URLSearchParams(search)
        url.forEach((value, key) => {


            if (!keys.includes(key)) {
                queryString = queryString.concat(`&${key}=${value}`)
            }
        })


        navigate({
            ...navigate.location,
            pathname: pathname,
            search: queryString,

        })
    }

    let lastPage = paginationRange[paginationRange.length - 1];
    useEffect(() => {
        const url = new URLSearchParams(search)
        if (!props.dynamo) {
            setPage(Number(url.get('page')))
        } else {
            setPage(prev => ({
                ...prev,
                ["lepk"]: url.get('lepk'),
                ["lesk"]: url.get('lesk'),
                ["legsipk"]: url.get('legsipk'),
                ["legsisk"]: url.get('legsisk'),
                ["pid"]: url.get('pid')
            }))
        }
    }, [search])
    useEffect(() => {

        if (!props.dynamo) {
            if (isNaN(page)) return
            if (!page || page <= 0) {
                props.setCurrentPage(1)
                setQueryParams(`?page=${1}`)
                onPageChange(1);
                return
            }
            props.setCurrentPage(Number(page))
            onPageChange(Number(page));
        } else {
            if (Object.keys(page).length === 0) return
            // props.setCurrentPage(1)
            // setQueryParams(`?lepk=${props.queryDynamo.lepk}&lesk=${props.queryDynamo.lesk}&legsipk=${props.queryDynamo.legsipk}&legsisk=${props.queryDynamo.legsisk}`)
            if (page?.pid) {

                onPageChange(Number(page?.pid <= totalCount ? page.pid : 1), page);
                props.setCurrentPage(Number(page?.pid <= totalCount ? page.pid : 1))
                return
            }
            dynamoQuerySetter(1)
            onPageChange(1);
            props.setCurrentPage(Number(1))
        }

    }, [page])
    const dynamoQuerySetter = (page) => {
        setQueryParams(`?lepk=${props.queryList[page].lepk}&lesk=${props.queryList[page].lesk}&legsipk=${props.queryList[page].legsipk}&pid=${page}&legsisk=${props.queryList[page].legsisk}`)
    }
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || totalCount < 1) {
        return (
            <div>
                {props.children}
            </div>
        )

    }
    return (
        <div className="pagination-component">
            <div>
                {props.children}
            </div>
            <div className={`pagination-component-wrapper ${props.hidePaginationMenu && 'hidePaginationMenu'}`}>
                {paginationRange.length > 1 ?
                    <ul
                        className={classnames('pagination-container', {[className]: className})}
                    >
                        {/* Left navigation arrow */}
                        <li
                            className={classnames('pagination-item', {
                                disabled: currentPage === 1
                            })}
                            onClick={onPrevious}
                        >
                            <i className="bi bi-chevron-left"></i>
                        </li>
                        {paginationRange.map((pageNumber, index) => {
                            // If the pageItem is a DOT, render the DOTS unicode character
                            if (pageNumber === DOTS) {
                                return <li className="pagination-item dots" key={index}>&#8230;</li>;
                            }

                            // Render our Page Pills
                            return (
                                <li
                                    className={classnames('pagination-item', {
                                        selected: pageNumber === currentPage
                                    })}
                                    onClick={() => {
                                        if (!props.dynamo) setQueryParams(`?page=${pageNumber}`)
                                        else {
                                            dynamoQuerySetter(pageNumber)
                                        }
                                        if (!props.dynamo) setPage(pageNumber)
                                        props.setCurrentPage(pageNumber)
                                    }}
                                    // style={
                                    //     paginationRange[index - 1] === currentPage || paginationRange[index + 1] === currentPage || paginationRange[index] === 1 ?
                                    //         {}
                                    //         :
                                    //         {pointerEvents: "none"}
                                    // }
                                    key={index}
                                >
                                    {pageNumber}
                                </li>
                            );
                        })}
                        {/*  Right Navigation arrow */}
                        <li
                            className={classnames('pagination-item', {
                                disabled: currentPage === lastPage
                            })}
                            onClick={onNext}
                        >
                            <i className="bi bi-chevron-right"></i>
                        </li>
                    </ul>
                    : ''}
            </div>
        </div>
    );
};

export default React.memo(CustomPagination);
