import React, { useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';

const StarRating = (props) => {

  const Star = ({ filled, onClick }) => (
    <span className='star-icon' onClick={onClick} >
      <Icon width={38} height={35} style={{ cursor: 'pointer', color: filled ? '#FF8C4B' : '#EEEEEE', }} icon={filled ? 'icon-park-solid:star' : 'icon-park-twotone:star'} />
    </span>
  );

  return (
    <div className='start-rating'>
      {[...Array(props.totalStars)].map((_, index) => (
        <Star
          key={index}
          filled={index < props.rating}
          onClick={() => props.setRating(index + 1)}
        />
      ))}
    </div>
  );
};

export default StarRating;