import React, { useState } from 'react';
import Select, { components } from 'react-select';
import "./ReactSelectField.scss";
import { Icon } from '@iconify/react/dist/iconify.js';

function ReactSelectField(props) {
    const [touchedAndNotSelected, setTouchedAndNotSelected] = useState(false);

    const CaretDownIcon = () => {
        return <Icon icon="ant-design:caret-down-filled" />;
      };
      
      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
            <CaretDownIcon />
          </components.DropdownIndicator>
        );
      };

    return (
        <div className={`select-field ${props.className}`}>
            {props.labelText && (
                <label htmlFor={props.id} className={`floating-label ${props.isFocused ? 'focused' : !!props.value ? 'focused' : ''}`}>
                    <span className={"label_name"}>{props.labelText} <strong
                        className={props.asterisk ? "asterisk name" : "name"}>{props.asterisk && '*'}</strong></span>
                </label>
            )}
            <Select
                className={`react-select-container  ${props.inputClassName ? "react-select__control--is-not-focused" : ""}`}
                classNamePrefix="react-select"
                name={props.name}
                components={{ DropdownIndicator }}
                onInputChange={props.onInputChange}
                placeholder={props.placeholder}
                id={props.id}
                isMulti={props.isMulti}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                options={props.options}
                isClearable={props.isClearable}
                onChange={props.onchangeCallback}
                value={props.value}
                defaultValue={props.defaultValue}
                noOptionsMessage={props.noOptionsMessage}
                isDisabled={props.disabled}
                isLoading={props.loading}
            />
            {props.requiredMessage ? (
                <span className="error-message">
                    {props.requiredMessageLabel}
                </span>
            ) : ''
            }
        </div>
    );
}

export default ReactSelectField;