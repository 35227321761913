import React from "react";
import './CustomCheckBox.scss'

const CustomCheckBox = (props) => {
    return(
        <div className={`custom-checkbox-filed ${props.className}`}>
            <input type="checkbox"
                id={props.id}
                autoComplete="off"
                name={props.inputName}
                value={props.value}
                onChange={props.onchangeCallback}
                ref={props.inputRef}
                defaultChecked={props.defaultChecked}
                disabled={props.disabled}
                checked={props.checked}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    )
}
export default CustomCheckBox;