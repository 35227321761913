export const maintenance = false;
export const web_base_url = 'https://app.harmonifi.com'
export const api_base_url = 'https://ccwgany8yd.execute-api.ap-south-1.amazonaws.com/prod'
export const cookie_url_endpoint = ''
export const auth0_redirected_endpoint = ''
export const social_api_base_url = ''
export const cloudfront_base_url = 'https://d2ezgvtvmxv5zk.cloudfront.net/'
export const bucket_base_url = 'https://harmonifi-media-conversion-stack-source-bucket-prod2.s3.ap-south-1.amazonaws.com/'
export const cognitoUserPoolId = 'ap-south-1_4TDGecZOr'
export const identityPoolId = 'ap-south-1:732e1453-64bc-4be3-af8f-c4258fcd453e'
export const bucket = 'harmonifi-media-conversion-stack-source-bucket-prod2'
export const region = 'ap-south-1'
export const cognito_app_client_id = '7dkj1sic99phkeh3an9ov5mfm9'
export const auth0_app_client_id = ''
export const DEFAULT_LANGUAGE = 'en'
export const messengerChatId = ''
export const web_socket_url = ''
