import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    ControlBar,
    PlaybackRateMenuButton,
    Player,
    VolumeMenuButton
} from "video-react";
import './static/ReactPlayerUpdate.scss'
import Hls from 'hls.js';
import { Dropdown, ButtonGroup, DropdownButton } from "react-bootstrap";
import FeedbackModal from "../Modals/FeedbackModal/FeedbackModal";
import speakerAnimated from "../../assets/image/speaker-animated.gif";

const HLSPlayer = ({ isClient, video_id, src, thumbnail_img }) => {
    const playerRef = useRef(null);
    const [hls, setHls] = useState(null);
    const [levels, setLevels] = useState([]);
    const [presentRes, setPresentRes] = useState(-1);
    const [currentLabel, setCurrentLabel] = useState('Auto');
    const [playerState, setPlayerState] = useState({});
    const [x, setX] = useState(window.innerWidth >= 576);
    const [showRewind, setShowRewind] = useState(false);
    const [showForward, setShowForward] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(false)
    const [playPauseIconShow, setPlayPauseIconShow] = useState(false)
    const [isMuted, setIsMuted] = useState(isClient !== undefined);

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@ WARNING WARNING WARNING WARNING @@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Do not remove this code videoElement.focus(); it works to focus on the video player when first time loads the player and without interacting inside the player keyboard will work automatically
    // using this code to prevent the video play/pause on click/touch
    // Now double tap on screen will work for forward and backward as per expectation
    useEffect(() => {
        const videoElement = playerRef.current?.video.video;

        if (videoElement) {

            // Handle isMute state when volume is changed or custom mute icon clicks (start)
            // Remove if you don't need this feature (check class .custom-unmute-btn)
            const handleVolumeChange = () => {
                setIsMuted(videoElement.muted);
            };

            const handleVolumeButtonClick = () => {
                setIsMuted(!videoElement.muted);
            };

            videoElement.addEventListener('volumechange', handleVolumeChange);

            // Get the speaker volume button element
            const volumeButtonElement = document.querySelector('.video-react-volume-menu-button');
            if (volumeButtonElement) {
                volumeButtonElement.addEventListener('click', handleVolumeButtonClick);
            }
            // Handle isMute state when volume is changed or custom mute icon clicks (end)


            // set player focused when video is playing
            videoElement.focus();

            const handleClick = (event) => {
                event.stopPropagation();
                event.preventDefault();
            };

            videoElement.addEventListener('click', handleClick);

            // By default, video-react-menu-content class is not blocked element that's why clicking outside the volume bar mutes/unmutes the video which is not good user experience
            // So, we need to block the click event on the volume bar to prevent the mute/unmute action
            // Also check video-react-menu-content class CSS in the ReactPlayerUpdate.scss file
            // Code starts here to prevent the mute/unmute action when clicking on the volume bar
            const volumeBarElement = document.querySelector('.video-react-menu-content');
            if (volumeBarElement) {
                const handleVolumeBarClick = (event) => {
                    // Stop the propagation of the click event
                    event.stopPropagation();
                    event.preventDefault();
                };

                volumeBarElement.addEventListener('click', handleVolumeBarClick);

                return () => {
                    volumeBarElement.removeEventListener('click', handleVolumeBarClick);
                };
            }
            // Code ends here to prevent the mute/unmute action when clicking on the volume bar


            // if you enable this code, touch will not work in mobile for any single/double tap
            // videoElement.addEventListener('touchstart', handleClick);

            return () => {
                videoElement.removeEventListener('volumechange', handleVolumeChange);
                if (volumeButtonElement) {
                    volumeButtonElement.removeEventListener('click', handleVolumeButtonClick);
                }

                videoElement.removeEventListener('click', handleClick);
                // if you enable this code, touch will not work in mobile for any single/double tap
                // videoElement.removeEventListener('touchstart', handleClick);
            };
        }
    }, []);

    // This state (setPlayerState) contains necessary information about the player state on real time
    // If you need to handle any action based on player state, you can use this state
    // No other purpose of this code
    useEffect(() => {
        const handleStateChange = (state) => {
            setPlayerState(state);
        };

        const player = playerRef.current;
        let subscription;

        if (player) {
            subscription = player.subscribeToStateChange(handleStateChange);
        }

        return () => {
            if (subscription) {
                subscription(); // Unsubscribe by calling the function
            }
        };
    }, []);

    // This code is used to handle the volume control VERTICAL using setX state in mobile view
    useEffect(() => {
        const handleResize = () => {
            setX(window.innerWidth >= 576);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // This code is used to handle the HLS player when video source is changed
    useEffect(() => {
        if (Hls.isSupported()) {
            const hlsInstance = new Hls();
            hlsInstance.loadSource(src);
            hlsInstance.attachMedia(playerRef.current.video.video);
            hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
                // Filter levels to remove those below 240p and start Auto from below resolution to higher
                // Means when user select Auto, it will start from the lowest resolution
                // then based on network speed it will switch to higher resolution
                const filteredLevels = hlsInstance.levels.filter(level => level.height >= 240);
                setLevels(filteredLevels);
                playerRef.current.video.video.play();
            });

            hlsInstance.on(Hls.Events.LEVEL_SWITCHED, (_, data) => {
                // Do something when level switched (data.level)
            });

            setHls(hlsInstance);

            // Ensure the initial level is set to auto (Hls.js default behavior)
            hlsInstance.currentLevel = -1;

            return () => {
                hlsInstance.destroy();
            };
        } else if (playerRef.current.video.video.canPlayType('application/vnd.apple.mpegurl')) {
            playerRef.current.video.video.src = src;
            playerRef.current.video.video.addEventListener('loadedmetadata', () => {
                playerRef.current.video.video.play();
            });
        }
    }, [src]);

    const handleLevelChange = (levelIndex, res) => {
        if (hls) {
            hls.currentLevel = levelIndex;
            setPresentRes(levelIndex);
            setCurrentLabel(res);
        }
    };

    const handleSeek = (direction) => {
        const currentTime = playerRef.current.getState().player.currentTime;
        const seekTime = direction === 'backward' ? currentTime - 10 : currentTime + 10;
        playerRef.current.seek(seekTime);
        if (direction === 'backward') {
            setShowRewind(true);
            setTimeout(() => setShowRewind(false), 500); // Hide icon after 300ms
        } else {
            setShowForward(true);
            setTimeout(() => setShowForward(false), 500); // Hide icon after 300ms
        }
    };

    const handleDoubleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const rect = event.target.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const width = rect.width;

        if (clickX < width / 2) {
            handleSeek('backward');
        } else {
            handleSeek('forward');
        }
    };

    const handleFeedbackModal = () => {
        setFeedbackModal(!feedbackModal)
    }

    const handleEnded = () => {
        // open modal only when client visits this page. currently this page we assume only for client
        if (isClient) {
            setFeedbackModal(true)
        }
    };


    const handleClicks = (event) => {
        const videoElement = playerRef.current?.video.video;
        videoElement.focus();
        // event.stopPropagation();
        if (videoElement.paused) {
            setPlayPauseIconShow(true);
            setTimeout(() => setPlayPauseIconShow(false), 250); // Hide icon after 300ms
            videoElement.play();
        } else {
            setPlayPauseIconShow(true);
            setTimeout(() => setPlayPauseIconShow(false), 250); // Hide icon after 300ms
            videoElement.pause();
        }
    };

    return (
        <Fragment>
            <div
                onDoubleClick={handleDoubleClick}
                style={{ position: 'relative', width: '100%', borderRadius: '4px', margin: '0 auto' }}
            >
                <div className="react-player" style={{ backgroundImage: `url(${thumbnail_img})` }}>
                    <div onClick={handleClicks}>
                        <div className={`custom-play-pause-center ${playerRef.current?.video.video.paused ? 'play' : 'pause'} ${playPauseIconShow ? 'show' : ''}`} ></div>
                    </div>

                    {
                        isClient && isMuted && <div className="custom-unmute-btn" onClick={() => {
                            setIsMuted(!isMuted);
                        }}>
                            <span>Click for sound</span><img src={speakerAnimated} alt="speaker"/>
                        </div>
                    }

                    <Player
                        ref={playerRef}
                        autoPlay
                        playsInline
                        poster={thumbnail_img}
                        onEnded={handleEnded}
                        tabIndex="0"
                        preload="auto"
                        fluid={true}
                        muted={isMuted} // Mute only for Client page as browser restrict to play video without user interaction
                    >
                        <source src={src} type="application/x-mpegURL" />
                        <ControlBar>
                            <PlaybackRateMenuButton rates={[2, 1.75, 1.50, 1.25, 1, 0.5]} order={7.1} />
                            <VolumeMenuButton vertical={!x} />
                            <div className="custom-resolution-control-main" order={7.2}>
                                {levels.length > 0 && (
                                    <div className="custom-resolution-control-main-inner">
                                        <DropdownButton
                                            as={ButtonGroup}
                                            drop={"up"}
                                            variant="secondary"
                                            title={currentLabel}
                                            className="custom-resolution-control-drop"
                                        >
                                            <Dropdown.Item
                                                active={presentRes === -1}
                                                className="btn-sm"
                                                onClick={() => handleLevelChange(-1, `Auto`)}>
                                                <small>Auto</small>
                                            </Dropdown.Item>
                                            {levels.map((level, index) => (
                                                <Dropdown.Item
                                                    active={presentRes === index}
                                                    key={index}
                                                    className="btn-sm"
                                                    onClick={() => handleLevelChange(index, `${level.height}p`)}
                                                    name={index}
                                                    eventKey={presentRes}
                                                >
                                                    <small>{level.height}p</small>
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                )}
                            </div>
                        </ControlBar>
                    </Player>
                </div>
                <div className={`double-click-icon ${showRewind ? 'show' : ''}`} style={{ left: '10%' }}>
                    {/* &#9664;&#9664; */}
                    <i className="fa-solid fa-caret-left"></i><i className="fa-solid fa-caret-left"></i>
                </div>
                <div className={`double-click-icon ${showForward ? 'show' : ''}`} style={{ right: '10%' }}>
                    {/* &#9654;&#9654; */}
                    <i className="fa-solid fa-caret-right"></i><i className="fa-solid fa-caret-right"></i>
                </div>
            </div>

            {
                feedbackModal && <div>
                    <FeedbackModal video_id={video_id} handleModalOpenClose={handleFeedbackModal} />
                </div>
            }
        </Fragment>
    );
};

export default HLSPlayer;
